import config from 'configs/config';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function useHandleUserNavigation() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');

  const navigate = useNavigate();
  const loginUser = (user, token) => {
    switch (user.type) {
      case 'student':
        if (redirectTo === 'codeToEarn') {
          navigate('/app/student/challenge-selection');
          return;
        }
        const isMobileDevice = /Mobi/.test(navigator.userAgent); // Check if 'Mobi' is present in the user agent

        const allowedMobileRoutes = ['/auth/token-login', '/app/student/chat'];

        if (allowedMobileRoutes.includes(window.location.pathname)) {
          return;
        }

        if (isMobileDevice) {
          navigate('/redirectWeb');
          return;
        }
        navigate('/app/student');
        break;
      case 'teacher':
        localStorage.clear();
        window.location.href = `${config.ADMIN_URL}/school/${user.school.id}?token=${token}`;
        break;
      // case 'parent':
      //   localStorage.clear();
      //   window.location.href = `${config.ADMIN_URL}/super-admin/dashboard?token=${token}`;
      //   break;
      case 'school_leader':
        localStorage.clear();
        window.location.href = `${config.ADMIN_URL}/school-management/school/${user?.school.id}?token=${token}`;
        break;
      default:
        localStorage.clear();
        window.location.href = `${config.ADMIN_URL}/super-admin/dashboard?token=${token}`;
        break;
    }
  };

  return { loginUser };
}
