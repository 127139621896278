import React, { useEffect } from 'react';

import { auth } from 'api/firebase';
import AuthPage from 'components/AuthPage';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AuthDetails from 'components/AuthDetails';
import Button from 'components/Button';
import Input from 'components/Input';
import Logo from 'components/Logo';
import SocialLogin from 'components/SocialLogin';

import useModal from 'components/Modal/hook/useModal';
import { useFormik } from 'formik';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import {
  useRegisterChildUserMutation,
  useRegisterFirebaseUserMutation,
} from 'store/apis/auth';
import * as Yup from 'yup';
import { selectTempUser } from 'store/Slice/uiSlice';
import { setAccessToken } from 'store/Slice/authSlice';
import { useNavigate } from 'react-router-dom';
import { containsOnlyAllowedCharacters } from 'utils/lib';

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username is required')
    .label('Username')
    .min(3, 'Username must be at least 3 characters')
    .max(80, 'Username must be at most 50 characters'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(50, 'Password must be at most 50 characters'),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .label('Confirm Password'),
});

export default function Under13SignupScreen() {
  const navigate = useNavigate();

  const {
    loginWithFirebase,
    isLoading: firebaseLoading,
    checkProfileAndNavigate,
  } = useFirebaseLogin();

  const params = new URLSearchParams(window.location.search);

  const { openModal } = useModal();

  const dispatch = useDispatch();

  const tempUser = useSelector(selectTempUser);

  const birthDate = params.get('birthDate');
  const childFirstName = params.get('childFirstName');
  const childLastName = params.get('childLastName');
  const parentFirstName = params.get('parentFirstName');
  const parentLastName = params.get('parentLastName');
  const dialCode = params.get('dialCode');
  const phoneNumber = params.get('phone');

  useEffect(() => {
    if (
      !birthDate ||
      !childFirstName ||
      !childLastName ||
      !phoneNumber ||
      !dialCode ||
      !parentFirstName ||
      !parentLastName
    ) {
      navigate('/home');
    }
  }, [
    birthDate,
    childFirstName,
    childLastName,
    dialCode,
    navigate,
    parentFirstName,
    parentLastName,
    phoneNumber,
  ]);

  const parentPhone = phoneNumber?.includes('+')
    ? phoneNumber
    : `+${dialCode}${phoneNumber}`;

  const [registerFirebaseUser, registerFirebaseUserResponse] =
    useRegisterFirebaseUserMutation();

  const [registerChildUser, registerChildUserRes] =
    useRegisterChildUserMutation();

  const [loader, setLoader] = useState(false);

  const nextNavigation = () => {
    openModal('AccountCreatedModal', {
      onContinue: () => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.append('username', formik.values.username);
        navigate('/complete-profile?' + searchParams.toString(), {
          replace: true,
        });
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        console.log('values', values);
        const data = {
          username: values.username,
          parentPhone: parentPhone,
          dateOfBirth: birthDate,
          password: values.password,
          confirmPassword: values.confirmPassword,
          tempUserId: tempUser?.id,
          childFirstName: childFirstName,
          childLastName: childLastName,
          parentFirstName: parentFirstName,
          parentLastName: parentLastName,
        };

        // if (!containsOnlyAllowedCharacters(values.username)) {
        //   setFieldError('username', 'Username contains invalid characters');
        //   return;
        // }

        const res = await registerChildUser(data).unwrap();
        if (res.data.customToken) {
          await auth?.signInWithCustomToken(res.data.customToken);
          const accessToken = await auth?.currentUser?.getIdToken();
          dispatch(setAccessToken(accessToken));

          if (accessToken) {
            checkProfileAndNavigate(res.data.user, accessToken, nextNavigation);
          } else {
            openModal('WarningModal', {
              title: 'Failed to get access token',
            });
          }
        } else {
          openModal('WarningModal', {
            title: 'Failed to get access token',
          });
        }
      } catch (err) {
        const message = Array.isArray(err?.data?.message)
          ? err?.data?.message[0]
          : err?.data?.message;

        openModal('WarningModal', {
          title: 'Failed to register user',
          description: message,
          // onContinue: () => {
          //   if (message === 'Username is already taken') {
          //     navigate('/login');
          //   }
          // },
        });
      }
    },
  });

  const socialAuthCallback = async ({ success }) => {
    setLoader(false);

    if (success) {
      const accessToken = await auth?.currentUser?.getIdToken();
      if (accessToken) {
        dispatch(setAccessToken(accessToken));

        try {
          const res = await registerFirebaseUser({
            dateOfBirth: birthDate,
            parentPhone: parentPhone,
            firebaseAccessToken: accessToken,
            tempUser,
          }).unwrap();

          checkProfileAndNavigate(res.data.user, accessToken, nextNavigation);
        } catch (err) {
          console.log(err, 'err');
          if (
            err?.data?.data === 'USER_ALREADY_REGISTERED' ||
            err?.data?.code === 'USER_ALREADY_REGISTERED' ||
            err?.data?.error === 'USER_ALREADY_REGISTERED'
          ) {
            await loginWithFirebase();
            return;
          }

          openModal('WarningModal', {
            title: 'Failed to register user',
            description: err?.data?.message,
          });
        }
      } else {
        openModal('WarningModal', {
          title: 'Failed to get access token',
        });
      }
    }
  };

  return (
    <AuthPage className="gap-4">
      <Logo className="w-32 mb-4" />
      <AuthDetails>
        <AuthDetails.TwoText
          leftText="Already have an account?"
          rightText="Login"
          rightTextUrl="/login"
        />
        <AuthDetails.Title>Create a G3MS Account!</AuthDetails.Title>
        <AuthDetails.Description>
          Earn gift cards while you learn—start rewarding your learning now!
        </AuthDetails.Description>
        <Button className="mx-auto" onClick={() => navigate('/qr')}>
          Scan QR Code
        </Button>
      </AuthDetails>

      <form className="grid gap-3 w-full" onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Enter unique username"
          name="username"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.username && formik.errors.username}
          value={formik.values.username}
          label={'Choose a unique username'}
        />
        <div className="grid gap-y-4 gap-x-3 lg:grid-cols-2">
          <Input
            placeholder="********"
            type={'password'}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password}
            value={formik.values.password}
            label="Password"
          />
          <Input
            placeholder="********"
            type={'password'}
            name="confirmPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            value={formik.values.confirmPassword}
            label="Confirm Password"
          />
        </div>

        <Button
          variant={'primary'}
          type="submit"
          isLoading={
            registerChildUserRes.isLoading ||
            firebaseLoading ||
            loader ||
            registerFirebaseUserResponse.isLoading
          }
          className="max-w-[20rem] w-full mx-auto mt-2"
        >
          Sign Up
        </Button>
      </form>
      <SocialLogin callback={socialAuthCallback} setIsLoading={setLoader} />
    </AuthPage>
  );
}
