import React from 'react';
import cn from 'utils/cn';

export default function Logo({ className = '' }) {
  return (
    <img
      src="/logo.svg"
      alt="g3ms"
      className={cn('w-24  md:w-28  xl:w-32 mx-auto', className)}
    />
  );
}
