import React from 'react';

import sharedStyles from '../../../sharedStyle.module.scss';

import Logo from '../../../components/logo.png';

import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import {
  appleProvider,
  auth,
  classLinkProvider,
  cleverProvider,
  googleProvider,
} from 'api/firebase';
import { useState } from 'react';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import analytics from 'utils/analytics';
import axios from '../../../../api/axios';
import { setUserDetails } from '../../../../store/Slice/authSlice';
import AppleIcon from '../../../components/Apple.png';
import Arrow from '../../../components/Arrow-Left.png';
import ClassLinkIcon from '../../../components/Classlink.png';
import GoogleIcon from '../../../components/Google.png';
import LoginPage from '../../../components/LoginPage.png';
import ParentsDialog from '../dialog';


export default function LoginUser({ steps, setStep }) {
  const [type, setType] = useState('other');
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.authReducer);
  const [formErrors, setFormErrors] = useState({});

  function signInBaseMethod() {
    const errors = {};

    if (!email) {
      errors.email = 'Email is required';
    }
    if (!password) {
      errors.password = 'Password is required';
    }
    setFormErrors(errors);
    if (!Object.keys(errors).length) {
      // if (isEmail(email)) {
      //   signInWithEmail();
      // } else {
      setLoading(true);
      axios
        .post('/auth/login/identifier', {
          identifier: email,
          password,
        })
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            NotificationManager.success('Login Successfully', 'Success', 3000);

            analytics.logEvent('login', {
              method: 'email',
              type,
            });

            // Construct access token
            auth
              .signInWithCustomToken(response.data.data.customToken)
              .then(async (userCredential) => {
                const accessToken = await userCredential.user.getIdToken();
                const refreshToken = await userCredential.user.refreshToken;
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                localStorage.setItem('uuid', response.data.data.user.id);
                localStorage.setItem(
                  'currentUser',
                  response.data.data.user.type,
                );
                localStorage.setItem(
                  'userData',
                  JSON.stringify(response.data.data.user),
                );
                dispatch(setUserDetails(response.data.data.user));
                analytics.loginUser(response.data.data.user.id);
              })
              .catch((error) => {
                analytics.apiError({ error, message: 'Error in login' });
                console.error(error);
              });
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error?.response?.data?.code === 'SOCIAL_LOGIN') {
            analytics.logEvent('social_login', {
              provider: error?.response?.data?.data?.provider,
            });
            const provider = error?.response?.data?.data?.provider;
            const providerMap = {
              'google.com': googleProvider,
              'apple.com': appleProvider,
              'oidc.classlink': classLinkProvider,
              'oidc.clever': cleverProvider,
            };
            if (providerMap[provider]) {
              signInWithProvider(providerMap[provider]);
            } else {
              NotificationManager.error(
                `Provider ${provider} is not supported`,
                'Error',
                3000,
              );

              analytics.apiError({
                error,
                message: 'Provider is not supported',
              });
            }
          } else if (error?.response?.data?.message) {
            if (typeof error?.response?.data?.message === 'string') {
              NotificationManager.error(
                error?.response?.data?.message,
                'Error',
                3000,
              );

              analytics.apiError({
                error,
                message: error?.response?.data?.message,
              });
            } else {
              for (let key in error?.response?.data?.message) {
                NotificationManager.error(
                  error?.response?.data?.message[key],
                  'Error',
                  3000,
                );

                analytics.apiError({
                  error,
                  message: error?.response?.data?.message[key],
                });
              }
            }
          }
        });
      // }
    }
  }

  function signInWithProvider(provider) {
    auth.signOut().then(() => {
      // clear cookies

      console.log('clear cookies');
      document.cookie.split(';').forEach(function (c) {
        console.log('clear cookie:', c);
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      });

      analytics.logoutUser();
    });
    setLoading(true);
    auth
      .signInWithPopup(provider)
      .then((user) => {
        onSignIn(user, 'social');
      })
      .catch(onError);
  }

  function signInWithEmail() {
    setLoading(true);
    return auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => onSignIn(user, 'email'))
      .catch(onError);
  }

  function onError(error) {
    if (
      !['auth/cancelled-popup-request', 'auth/popup-closed-by-user'].includes(
        error.code,
      )
    ) {
      analytics.apiError({ error, message: 'Error in login' });
      setLoading(false);
      NotificationManager.error(error.message, 'Error', 3000);
    }
  }

  const dispatch = useDispatch();

  async function onSignIn(userCredential, provider = 'email') {
    axios
      .post('/users/login', {
        email: userCredential.user.email,
        firebaseId: userCredential.user.uid,
        userType: type.toLowerCase(),
      })
      .then(async (res) => {
        NotificationManager.success(userCredential.user.displayName, 'Success');
        analytics.logEvent('login', {
          method: provider,
          type,
        });
        const token = res.data.token;
        console.log('================/users/login===============', res);

        localStorage.setItem('uuid', res.data.id);
        localStorage.setItem('userData', JSON.stringify(res.data));

        if (
          !res?.data?.profileImage ||
          (!res?.data?.completedProfile &&
            !res?.data?.schoolId &&
            res?.data?.type !== 'super_admin')
        ) {
          const accessToken = await userCredential.user.getIdToken();
          localStorage.setItem('accessToken', accessToken);
        } else {
          localStorage.setItem('accessToken', token);
        }
        dispatch(setUserDetails(res.data));
      })
      .catch((err) => {
        console.log('err', err);
        analytics.apiError({ error: err, message: 'Error in login' });
        if (
          err?.response?.data?.error === 'Bad Request' &&
          provider === 'social'
        ) {
          onRegister(userCredential);
        } else {
          NotificationManager.error(
            err?.response?.data?.message,
            'Error',
            3000,
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onRegister(userCredential) {
    axios
      .post('/user', {
        email: userCredential.user.email,
        firebaseId: userCredential.user.uid,
        type: type.toLowerCase(),
      })
      .then((response) => {
        console.log('data', response.data);
        setLoading(false);

        if (response.status === 201) {
          NotificationManager.success(response.data.data.email, 'Success');
          localStorage.setItem('uuid', response.data.data.id);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('currentUser', type.toLowerCase());
          localStorage.setItem('userData', JSON.stringify(response.data.data));
          setStep((prevStep) => ({
            ...prevStep,
            current: 'detail',
          }));
          analytics.logEvent('register', {
            method: 'email',
            type,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        analytics.apiError({ error, message: 'Error in register' });
        console.log(error);
        NotificationManager.error('error', 'Error', 3000);
      });
  }

  return (
    <Box className={`${sharedStyles.container}`}>
      <Stack className={`${sharedStyles.studentsContainer}`}>
        <Box className={sharedStyles.arrow}>
          <img
            src={Arrow}
            alt="img-discription"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setStep((prevStep) => ({
                ...prevStep,
                current: 'home',
              }));
            }}
          />
        </Box>
        <Box className={sharedStyles.imageLeft}>
          <img src={LoginPage} alt="img-discription" />
        </Box>
      </Stack>
      <Stack
        className={`${sharedStyles.loginContainer} ${sharedStyles.signupContainer} `}
      >
        <Box className={sharedStyles.logoWrapper}>
          <Box className={sharedStyles.arrow}>
            <img
              src={Arrow}
              alt="img-discription"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setStep((prevStep) => ({
                  ...prevStep,
                  current: 'home',
                }));
              }}
            />
          </Box>
          <Box className={sharedStyles.logo}>
            <img src={Logo} alt={'logo'} />
          </Box>
        </Box>

        <Typography className="!font-medium !text-2xl">
          Don't have an account?{' '}
          <span
            style={{ color: '#C73E9D', fontWeight: '700', cursor: 'pointer' }}
            onClick={() =>
              setStep((prevStep) => ({
                ...prevStep,
                current: 'birth',
              }))
            }
          >
            Sign Up
          </span>{' '}
        </Typography>
        <Stack className="w-10/12">
          <Typography
            className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
            style={{ color: '#131515' }}
          >
            Welcome Back!
          </Typography>
          <Typography
            className="w-9/12 sm:w-10/12 md:w-3/5 block !text-black !text-center !text-lg sm:!text-lg md:!text-xl lg:!text-xl !mx-auto !my-3 "
            style={{ color: '#979C9E' }}
          >
            Give your students the support they need to close learning gaps.
          </Typography>
        </Stack>
        <Button
          variant={'outlined'}
          size="small"
          sx={{
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '20px',
          }}
          className={'primary_bg_btn w-9/12 sm:w-10/12 md:w-5/12'}
          onClick={() =>
            setStep((prevStep) => ({
              ...prevStep,
              current: 'qr',
            }))
          }
        >
          Scan QR Code
        </Button>

        {/* <CircularProgress
          size={30}
          style={{
            display: loading ? 'block' : 'none',
            margin: 'auto',
            marginTop: '20px',
          }}
          styles={buildStyles({
            pathColor: '#C73E9D',
          })}
        /> */}

        <Stack className="w-9/12 sm:w-10/12 md:w-3/5" gap={4}>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-input': {
                padding: '5.5px 14px',
                paddingTop: '30px',
                fontSize: '18px',
                fontWeight: 500,
              },
            }}
          >
            <InputLabel
              htmlFor="my-textfield"
              style={{
                marginTop: '-10px',
                fontWeight: 600,
                fontSize: '15px',
                color: '#130F26',
              }}
            >
              Email or Username
            </InputLabel>
            <TextField
              placeholder="test@example.com or happystudent123"
              id="my-textfield"
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              error={formErrors.email}
              helperText={formErrors.email}
            />
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-input': {
                padding: '5.5px 14px',
                paddingTop: '30px',
              },
            }}
          >
            <InputLabel
              htmlFor="my-textfield"
              style={{
                marginTop: '-10px',
                fontWeight: 600,
                fontSize: '15px',
                color: '#130F26',
              }}
            >
              Password
            </InputLabel>
            <TextField
              placeholder="********"
              type={showPassword ? 'text' : 'password'}
              id="my-textfield"
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
              error={formErrors.password}
              helperText={formErrors.password}
            />
          </FormControl>
        </Stack>
        <Box className="flex items-center justify-start w-[60%] -mb-2">
          <Checkbox
            value={showPassword}
            onChange={() => setShowPassword(!showPassword)}
          />
          <Typography
            className="!font-normal"
            style={{ color: '#292929', fontSize: '14px' }}
          >
            Show password
          </Typography>
        </Box>
        <Button
          variant={'contained'}
          size="small"
          sx={{
            width: '60%',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '20px',
            borderWidth: '2px',
          }}
          className={'primary_bg_btn'}
          onClick={signInBaseMethod}
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Login'}
        </Button>
        <Box className={sharedStyles.orField}>
          <Box className={sharedStyles.line}></Box>
          <Typography>OR</Typography>
          <Box className={sharedStyles.line}></Box>
        </Box>
        <Box sx={{ margin: '0 auto' }}>
          <Box className={sharedStyles.buttowrap}>
            <Box className={sharedStyles.button}>
              <button
                className={sharedStyles.loginButton}
                onClick={() => signInWithProvider(appleProvider)}
                style={{
                  backgroundColor: '#FB1644',
                  marginBottom: '10px',
                }}
              >
                <Typography className={sharedStyles.applink}>
                  <img src={AppleIcon} alt={'apple logo icon'} />
                </Typography>
              </button>
              <Typography className={sharedStyles.appstatus}>
                Login with <span style={{ color: '#000' }}>Apple ID</span>
              </Typography>
            </Box>
            <Box className={sharedStyles.button}>
              <button
                className={sharedStyles.loginButton}
                onClick={() => signInWithProvider(googleProvider)}
                style={{
                  backgroundColor: '#DF4B3C',
                  marginBottom: '10px',
                }}
              >
                <Typography className={sharedStyles.applink}>
                  <img src={GoogleIcon} alt={'google logo icon'} />
                </Typography>
              </button>
              <Typography className={sharedStyles.appstatus}>
                Login with <span style={{ color: '#000' }}>Google</span>
              </Typography>
            </Box>
            <Box className={sharedStyles.button}>
              <button
                className={sharedStyles.loginButton}
                style={{
                  backgroundColor: '#1BA0ED',
                  marginBottom: '10px',
                }}
                onClick={() => signInWithProvider(classLinkProvider)}
              >
                <Typography className={sharedStyles.applink}>
                  <img src={ClassLinkIcon} alt={'class link logo icon'} />
                </Typography>
              </button>
              <Typography className={sharedStyles.appstatus}>
                Login with <span style={{ color: '#000' }}>Class Link</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Stack>
      <ParentsDialog />
    </Box>
  );
}
