import { apiSlice } from '..';

export const homeTabApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getGradeList: builder.query({
      query: () => 'grades',
    }),
    getMoreGradeList: builder.query({
      query: page => `grades?page=${page}`,
      onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
        const {data} = await queryFulfilled;
        dispatch(
          homeTabApiSlice.util.updateQueryData(
            'getGradeList',
            undefined,
            draft => {
              draft?.data?.push(...data);
            },
          ),
        );
      },
    }),
    updateGrade: builder.mutation({
      query: data => ({
        url: 'user/student/grade',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['MyProfile', 'getVideos'],
    }),
  }),
});

export const {
  useGetGradeListQuery,
  useGetMoreGradeListQuery,
  useUpdateGradeMutation,
} = homeTabApiSlice;
