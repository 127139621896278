import { createSlice } from '@reduxjs/toolkit';
import { auth } from 'api/firebase';

const initialState = {
  user: undefined,
  accessToken: null,
  refreshToken: null,
};

const AuthSlice = createSlice({
  name: 'auth_slice',
  initialState: initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload?.refreshToken;
    },
    logoutUser: (state) => {
      state.user = undefined;
      state.accessToken = undefined;
      state.refreshToken = undefined;
      auth.signOut();
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
});

export const { setUserDetails, logoutUser, setAccessToken } = AuthSlice.actions;

export const selectUser = (state) => state.authReducer.user;
export const selectAccessToken = (state) => state.authReducer.accessToken;

const authReducer = AuthSlice.reducer;

export default authReducer;
