import React, { useState } from 'react';

import AuthDetails from 'components/AuthDetails';
import AuthPage from 'components/AuthPage';
import Input from 'components/Input';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { setBirthDate } from 'store/Slice/registerSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import analytics from 'utils/analytics';

import Button from 'components/Button';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

export default function SelectBirthDate() {
  const { openModal } = useModal();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { date } = useAppSelector((state) => state.registerReducer);
  const handleDate = (name, value) => {
    if (value === '') {
      dispatch(setBirthDate({ key: name, value }));
      return;
    }
    if (name === 'day' && value.length > 2) return;
    if (name === 'month' && value.length > 2) return;
    if (name === 'year' && value.length > 4) return;
    if (isNaN(parseInt(value))) return;
    dispatch(setBirthDate({ key: name, value: value }));
  };

  function isUnder13(birthDate) {
    // Parse the birth date string using moment
    const birth = moment(birthDate, 'DD/MM/YYYY');

    // Get the current date using moment
    const today = moment();

    // Calculate the age in years
    const age = today.diff(birth, 'years');

    // Check if the age is under 13
    return age < 13;
  }

  function handleNext() {
    if (date.day === '') {
      NotificationManager.error('Please enter a valid day', 'Error');
      return;
    }

    if (date.month === '') {
      NotificationManager.error('Please enter a valid month', 'Error');
      return;
    }

    if (date.month > 12) {
      NotificationManager.error('Please enter a valid month', 'Error');
      return;
    }

    if (date.year === '') {
      NotificationManager.error('Please enter a valid year', 'Error');
      return;
    }

    if (date.day > 31) {
      NotificationManager.error('Please enter a valid day', 'Error');
      return;
    }

    const day = parseInt(date.day);
    const month = parseInt(date.month);
    const year = parseInt(date.year);

    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    if (isLeapYear(year)) {
      if (month === 2 && day > 29) {
        NotificationManager.error('Please enter a valid day', 'Error');
        return;
      }
    } else {
      if (month === 2 && day > 28) {
        NotificationManager.error('Please enter a valid day', 'Error');
        return;
      }
    }

    analytics.logEvent('set_birth_date', {
      birth_date: `${date.day}/${date.month}/${date.year}`,
      is_under_13: isUnder13(`${date.day}/${date.month}/${date.year}`),
    });

    if (isUnder13(`${date.day}/${date.month}/${date.year}`)) {
      openModal('AskAGrownupModal', {
        birthDate: dayjs(`${date.year}-${date.month}-${date.day}`).format(
          'YYYY-MM-DD',
        ),
      });
    } else {
      const url = `/phone-validation?${queryString.stringify({
        birthDate: dayjs(`${date.year}-${date.month}-${date.day}`).format(
          'YYYY-MM-DD',
        ),
      })}`;
      navigate(url);
    }
  }

  return (
    <AuthPage className="gap-5">
      <Logo className="w-36 mb-14" />
      <AuthDetails>
        <AuthDetails.TwoText
          leftText="Already have an account?"
          rightText="Login"
          rightTextUrl="/login"
        />
        <AuthDetails.Title>What is your birth date?</AuthDetails.Title>
        <AuthDetails.Description>
          The G3MS learn to earn platform is designed to keep kids safe online
          while letting them learn anywhere on any device!
        </AuthDetails.Description>
      </AuthDetails>

      <div className="grid md:grid-cols-3 gap-6 w-full">
        <Input
          label="Month"
          name="month"
          value={date.month}
          onChange={(event) =>
            handleDate(event.target.name, event.target.value)
          }
          placeholder="MM"
          type="number"
        />

        <Input
          label="Day"
          name="day"
          value={date.day}
          onChange={(event) =>
            handleDate(event.target.name, event.target.value)
          }
          placeholder="DD"
          type="number"
        />

        <Input
          label="Year"
          name="year"
          value={date.year}
          onChange={(event) =>
            handleDate(event.target.name, event.target.value)
          }
          placeholder="YYYY"
          type="number"
          min="1900"
        />
      </div>

      <Button
        variant={'primary'}
        className={'max-w-[25rem] mt-4 mx-auto'}
        onClick={handleNext}
      >
        Next
      </Button>
    </AuthPage>
  );
}
