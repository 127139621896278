import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'utils/cn';

export default function TwoTextLink({
  leftText = 'Already have an account?',
  rightText = 'Login',
  rightTextUrl = '/login',
  className = '',
}) {
  return (
    <p className={cn("font-medium text-base 2xl:text-xl text-center mx-auto mb-5", className)}>
      {leftText}{' '}
      <Link
        style={{
          color: '#C73E9D',
          fontWeight: '700',
          cursor: 'pointer',
        }}
        to={rightTextUrl}
      >
        {rightText}
      </Link>
    </p>
  );
}
