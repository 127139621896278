import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/modal/modal';
import styles from './styles.module.scss';
import ConvinceUserToUpgradeModal from './SuccessModal';

const Index = ({ setStep }) => {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(true);

    useEffect(() => {
        !modalOpen && navigate('/plans');
    }, [modalOpen])

    return (
        <>
            <Modal open={modalOpen} setOpen={setModalOpen} overlayStyles={styles.modalOverlay} contentStyles={styles.modalContent}>
                <ConvinceUserToUpgradeModal setStep={setStep} />
            </Modal>
        </>
    );
};

export default Index;