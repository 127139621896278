import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

import Close from '../../../asset/payment/x-close.svg';
import SuccessfullyRecievedEmoji from '../../../asset/qr_not_recieved.svg';

const Index = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.modalOuterShadow}>
      <div className={styles.convinceToUpgradeContainer}>
        <header className={styles.convinceToUpgradeHead}>
            <img src={SuccessfullyRecievedEmoji} alt="" />

            {/* close icon  */}
            <img className={styles.close} src={Close} onClick={() => navigate('/plans')} loading="lazy" alt="" />
        </header>
        <main className={styles.convinceToUpgradeBody}>
          <h3 className={styles.heading}>Oops! We couldn’t complete your payment.</h3>
          <p className={styles.bodySmTxt}>It seems there’s an issue with your transaction. Please check the following:</p>
          <ul>
           <li>	•	Ensure card details are correct.</li>
           <li>	•	Check for a stable internet connection.</li>
          </ul>
          <br />
          <p className={styles.bodySmTxt}>If the problem persists, try again or contact our support or your bank for assistance.</p>
        </main>
        <footer className={styles.convinceToUpgradeFooter}>
          <button onClick={() => navigate('/plans')}>Contact Support</button>
          <button onClick={() => navigate('/plans')}>Retry</button>
        </footer>
      </div>
    </div>
  );
}

export default Index;  