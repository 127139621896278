import React from 'react';

import useModal from '../../hook/useModal';
import Modal from '../../components/Modal';
import sad from './assets/sad.svg';
import AuthDetails from 'components/AuthDetails';
import Button from 'components/Button';

export default function InvalidOtpModal() {
  const { closeModal, isOpen, modal } = useModal();


  return (
    <Modal isOpen={isOpen('InvalidOtpModal')} onClose={closeModal}>
      <Modal.Content className='[--max-width:_40rem]'>
        <Modal.CloseButton />
        <div className="flex flex-col gap-8 py-4">
          <img
            src={sad}
            alt="sad"
            className="size-8 md:size-10 lg:size-12 mx-auto"
            size="sm"
            mx={'auto'}
          />

          <AuthDetails.Title>Oops! Invalid Code.</AuthDetails.Title>

          <div className="grid gap-4">
            <p className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
              The code you entered is incorrect. Please ensure:
            </p>

            <ul className="list-disc list-inside">
              <li className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
                You’ve entered the correct code sent to your email address
              </li>
              <li className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
                Ensure you’ve entered all 6 digits correctly
              </li>
            </ul>

            <p className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
              If the code has expired, request a new one.
            </p>
          </div>

          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <Button className="max-w-none w-full" onClick={modal?.props?.onResend}>
              Resend Code
            </Button>
            <Button
              className="max-w-none w-full"
              variant="primary"
              onClick={closeModal}
            >
              Try Again
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
