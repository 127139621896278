import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingOverlay, Modal, ModalBody } from '@mantine/core';
import Button from 'components/Button';
import Logo from 'components/Logo';
import dayjs from 'dayjs';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import { useSelector } from 'react-redux';
import { usePDF } from 'react-to-pdf';
import { useGetSessionDetailsQuery } from 'store/apis/payment';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { selectAccessToken } from 'store/Slice/authSlice';
import Download from './assets/download.svg';

const PaymentConfirmation = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [open, setOpen] = React.useState(true);

  const { data: mtyProfileData, isLoading: loading } = useGetMyProfileQuery();

  const { checkProfileAndNavigate } = useFirebaseLogin();
  const accessToken = useSelector(selectAccessToken);

  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

  const sessionId = queryParams.get('sessionId');

  const { data: paymentData, isLoading } = useGetSessionDetailsQuery(
    sessionId,
    {
      skip: !sessionId,
    },
  );

  const data = React.useMemo(
    () => [
      {
        title: 'Package',
        value:
          paymentData?.data?.details?.subscriptionDetails?.[0]?.product?.name,
      },
      {
        title: 'User',
        value:
          paymentData?.data?.userInfo?.name +
          ' ' +
          paymentData?.data?.userInfo?.lastname,
      },
      { title: 'References Number', value: sessionId?.slice(0, 12) + '...' },
      {
        title: 'Date',
        value: dayjs(paymentData?.data?.timestamp).format('MMM DD, YYYY'),
      },
      {
        title: 'Time',
        value: dayjs(paymentData?.data?.timestamp).format('hh:mm A'),
      },
      //   { title: 'Payment Method', value: 'Credit Card' },
    ],
    [paymentData, sessionId],
  );

  const handleNext = () => {
    if (
      accessToken &&
      mtyProfileData?.data &&
      mtyProfileData?.data?.type === 'student'
    ) {
      checkProfileAndNavigate(mtyProfileData?.data, accessToken);
      return;
    }
    if (paymentData?.data) {
      const phone = paymentData?.data?.userInfo?.phone;
      const birthDate = paymentData?.data?.userInfo?.dateOfBirth;
      navigate(`over13?phone=${phone}&birthDate=${birthDate}`);
      return;
    }
    navigate('/birth');
  };

  return (
    <Modal opened={open} centered withCloseButton={false} size={'35rem'}>
      <ModalBody>
        <div>
          <div
            className="bg-white p-2 rounded-lg w-full mx-auto"
            ref={targetRef}
          >
            {/* <img
              src={ConfirmationIcon}
              alt=""
              className="size-16 xl:size-20 2xl:size-24 mx-auto"
            /> */}

            <Logo className="mx-auto" />

            <header>
              <h2 className="text-center mx-auto mt-4 font-semibold text-xl xl:text-2xl 2xl:text-3xl">
                Payment Confirmed!
              </h2>
            </header>

            <hr className="border-t-2 border-[#DCDEE0] my-8 border-dashed " />

            <main>
              <div className="grid gap-4 py-4">
                {data?.map((item, _index) => (
                  <div key={_index} className="flex justify-between gap-4">
                    <h4 className="text-[#707070]">{item?.title}</h4>
                    <span className="text-[#121212] font-medium">
                      {item?.value}
                    </span>
                  </div>
                ))}
              </div>

              <hr />

              <div className="flex justify-between gap-4 py-4">
                <h4>Amount</h4>
                <span>${paymentData?.data?.amountTotal / 100}</span>
              </div>
            </main>
          </div>
          <hr />

          <button
            className="flex items-center justify-center font-medium w-full gap-2 bg-[#F9FAFB] border border-[#DCDEE0] rounded-lg py-3 px-4 mt-4"
            onClick={() => {
              toPDF();
            }}
          >
            <img
              src={Download}
              alt=""
              className="size-4 xl:size-5 2xl:size-6"
            />
            Get PDF Receipt
          </button>

          <Button
            className="w-full mt-4 max-w-full "
            variant={'primary'}
            onClick={() => {
              setOpen(false);
              handleNext();
            }}
          >
            Continue
          </Button>
        </div>
      </ModalBody>
      <LoadingOverlay visible={isLoading} />
    </Modal>
  );
};

export default PaymentConfirmation;
