import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

import Close from '../../../asset/payment/x-close.svg';

const Index = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.modalOuterShadow}>
      <div className={styles.convinceToUpgradeContainer}>
        <header className={styles.convinceToUpgradeHead}>
            <h3 className={styles.heading}>Your Child Requested an Upgrade</h3>
            <img className={styles.close} src={Close} onClick={() => navigate('/plans')} loading="lazy" alt="" />
        </header>
        <main className={styles.convinceToUpgradeBody}>
          <h3 className={styles.heading}>Zayn Smith is asking you to upgrade their G3MS account to unlock premium features.</h3>
          <h3 className={styles.heading} style={{textAlign: 'left'}}>Premium Features Include</h3>
          <p className={styles.bodyTxt} style={{textAlign: 'left'}}>Here’s what Zayn Smith will enjoy with G3MS Premium:</p>
          <ul>
           <li>✅ Full access to Math, ELA, Science, and Social Studies lessons.</li>
           <li>✅ Personalized AI Tutor for tailored learning support.</li>
           <li>✅ Earn redeemable tokens for rewards and gift cards.</li>
           <li>✅ Advanced progress tracking and analytics.</li>
          </ul>
          <div>
            <h3 className={styles.heading}>Plans start at $7.99/month with a 7-day free trial.</h3>
            <h3 className={styles.heading} style={{marginBottom: '0px'}}>Cancel anytime with no commitment.</h3>
          </div>
        </main>
        <footer className={styles.convinceToUpgradeFooter}>
          <button onClick={() => navigate('/plans')}>Reject</button>
          <button onClick={() => navigate('/plans')}>Approve & View Payment Options</button>
        </footer>
      </div>
    </div>
  );
}

export default Index;  