import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import Slider from 'react-slick';
import OnboardingCard from './OnboardingCard';
import videoImage from '../assets/videos.png';

const caroselItems = [
  {
    image: require('../assets/learn-to-earn.svg').default,
    title: 'Learn to earn',
  },
  {
    image: require('../assets/ai-tutor.svg').default,
    title: 'AI tutor',
  },
  {
    image: videoImage,
    title: 'Videos',
  },
  {
    image: require('../assets/quizzes.svg').default,
    title: 'Quizzes',
  },
];

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  width: '100%',
  centerMode: true,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  centerPadding: '60px',
};

export default function MobileSlider() {
  return (
    <div className="w-full max-w-[calc(100vw-4rem)]">
      <Slider {...settings} className="w-full variable-width center">
        {caroselItems.map((item, index) => (
          <OnboardingCard key={index} {...item} />
        ))}
      </Slider>
    </div>
  );
}
