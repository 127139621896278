import React from 'react';
import cn from 'utils/cn';

export default function AuthSectionTitle({ children, className = '' }) {
  return (
    <h1
      className={cn(
        '!font-semibold text-xl xl:!text-2xl 2xl:text-3xl text-center text-[#131515] mx-auto',
        className,
      )}
    >
      {children}
    </h1>
  );
}
