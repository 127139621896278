import styles from '../styles.module.scss';
import React, { useState, useEffect } from 'react';
import CardMockup from '../../../asset/card-mockup.jpg';
import AITutor from '../../../asset/splash/ai-tutor.png';
import AIChat from '../../../asset/splash/ai-chat.png';
import Quiz from '../../../asset/splash/quiz.png';

const Index = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    { name: 'Learn to Earn', img: CardMockup },
    { name: 'AI Tutor', img: AITutor },
    { name: 'Videos', img: AIChat },
    { name: 'Quizzes', img: Quiz },
  ];

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? items?.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex === items?.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const autoplay = setInterval(() => {
      handleNext();
    }, 8000);
    return () => clearInterval(autoplay);
  }, []);

  return (
    <div className="w-full py-8 flex flex-col items-center px-8">
      <div className="w-full flex overflow-hidden">
        {items.map((item, index) => (
          <div
            key={index}
            className={`transition-all duration-500 ease-in-out flex-shrink-0 cursor-pointer ${
              activeIndex === index ? 'w-2/5' : 'w-1/5'
            }`}
            onClick={() => setActiveIndex(index)}
          >
            <div 
              style={{boxShadow: '0px 2.52px 5.04px 0px #E9EAEB'}}
              className="flex flex-col items-center bg-white border border-[#E9EAEB] shadow-md rounded-3xl overflow-hidden mx-2">
              <img
                alt={item.name}
                style={{height: '400px'}}
                src='https://picsum.photos/300/300'
                className="w-full h-auto object-cover rounded-3xl !rounded-b-none"
              />
              <h3 className='text-2xl text-[#181D27] font-bold pt-8 pb-4'>{item.name}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;