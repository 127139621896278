import React, { useState, useEffect } from 'react';
import sharedStyles from '../../../sharedStyle.module.scss';
import Logo from '../../../components/logo.png';
import Arrow from '../../../components/Arrow-Left.png';
import HomeLogin from '../../../components/StudentsSecond.png';
import OTPInput from 'otp-input-react';
import { Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useVerifyPhoneOtpMutation } from 'store/apis/otp';
import { NotificationManager } from 'react-notifications';
import { useSendPhoneOtpMutation } from 'store/apis/otp';
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input/input';

const MAX_ATTEMPTS = 3;
const RETRY_DELAY = 60; // 1 minute in seconds
const RESEND_DELAY = 60; // 1 minute in seconds
const LOCKOUT_DELAY = 600; // 10 minutes in seconds;

export default function OtpValidation({ setStep, type, phoneNumber, verificationSid }) {
  const [otp, setOtp] = useState('');
  const [shake, setShake] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [retryTimer, setRetryTimer] = useState(0);
  const [resendTimer, setResendTimer] = useState(60);
  const [lockoutTimer, setLockoutTimer] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitOtp, submitOtpRes] = useVerifyPhoneOtpMutation();
  const [sendPhoneOtp, sendPhoneOtpRes] = useSendPhoneOtpMutation();

  // Timer for retries
  useEffect(() => {
    let retryInterval;
    if (retryTimer > 0) {
      retryInterval = setInterval(() => {
        setRetryTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      clearInterval(retryInterval);
    }
  }, [retryTimer]);

  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    }
  }, [resendTimer]);

  // Timer for lockout
  useEffect(() => {
    let interval;
    if (lockoutTimer > 0) {
      interval = setInterval(() => {
        setLockoutTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [lockoutTimer]);

  const handleVerifyPhoneOtp = async () => {
    if (lockoutTimer > 0) {
      setErrorMessage(`You are temporarily locked out. Please wait ${Math.ceil(lockoutTimer / 60)} minutes.`);
      return;
    }

    try {
      const res = await submitOtp({ verificationSid, otp }).unwrap();
      if (res?.data?.isOtpValid) {
        NotificationManager.success('OTP verified successfully');
        setStep((prevStep) => ({ ...prevStep, current: 'signup', phoneNumber }));
      } else {
        handleOtpFailure();
      }
    } catch (error) {
      if (error?.status >= 500) {
        NotificationManager.error('Server error. Please try again later.');
      } else {
        handleOtpFailure();
      }
    }
  };

  const handleOtpFailure = () => {
    setShake(true);
    setTimeout(() => setShake(false), 500);

    setAttempts((prev) => prev + 1);
    if (attempts + 1 >= MAX_ATTEMPTS) {
      if (retryTimer > 0) {
        setErrorMessage(`You've reached the maximum attempts. Please wait ${retryTimer} seconds before trying again.`);
      } else if (lockoutTimer === 0) {
        setErrorMessage('Too many failed attempts. You are locked out for 10 minutes.');
        setLockoutTimer(LOCKOUT_DELAY);
      }
    } else {
      setErrorMessage('The OTP you entered is incorrect. Please try again.');
      setRetryTimer(RETRY_DELAY);
    }
  };

  const handleResendOtp = async () => {
    setOtp('')
    setErrorMessage('')
    
    if (retryTimer > 0) {
      setErrorMessage(`Please wait ${retryTimer} seconds before resending OTP.`);
      return;
    }

    const handleSendPhoneOtp = async () => {
      try {
        if (!isValidPhoneNumber(phoneNumber)) {
          NotificationManager.error('Please enter a valid phone number');
          return;
        }
    
        const phonNum = parsePhoneNumber(phoneNumber);
    
        const res = await sendPhoneOtp({
          countryCode: `+${phonNum.countryCallingCode}`,
          phoneNumber: phonNum.nationalNumber?.length == 9 ? `0${phonNum.nationalNumber}` : phonNum.nationalNumber,
        }).unwrap();
        NotificationManager.info('New OTP has been sent to your registered phone number.');
  
        setStep((prevStep) => ({
          ...prevStep,
          current: 'phoneOtp',
          phoneNumber: phoneNumber,
          verificationSid: res.data.verificationSid,
        }));

        setAttempts((prev) => prev + 1);
        if (attempts + 1 >= MAX_ATTEMPTS) {
          if (retryTimer > 0) {
            setErrorMessage(`You've reached the maximum resend attempts. Please wait ${retryTimer} seconds before trying again.`);
          } else if (lockoutTimer === 0) {
            setErrorMessage('Too many resend attempts. You are locked out for 10 minutes.');
            setLockoutTimer(LOCKOUT_DELAY);
          }
        }

      } catch (error) {
        if (error?.status === 500) {
          NotificationManager.error('Something went wrong.');
        } else if (error?.data?.message) {
          NotificationManager.error(error?.data?.message);
        } else {
          NotificationManager.error('Something went wrong.');
        }
      }
    };

    handleSendPhoneOtp()
  };

  return (
    <Box className={`${sharedStyles.container}`}>
      <Stack className={`${sharedStyles.studentsContainer}`}>
        <Box className={sharedStyles.arrow}>
          <img
            src={Arrow}
            alt="Back"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              setStep((prevStep) => ({
                ...prevStep,
                current: 'phoneValidation',
              }))
            }
          />
        </Box>
        <Box className={sharedStyles.imageLeft}>
          <img src={HomeLogin} alt="Home" />
        </Box>
      </Stack>
      <Stack className={`${sharedStyles.loginContainer}`}>
        <Box className={sharedStyles.logoWrapper}>
          <Box className={sharedStyles.arrow}>
            <img
              src={Arrow}
              alt="img-discription"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setStep((prevStep) => ({
                  ...prevStep,
                  current: 'phoneValidation',
                }));
              }}
            />
          </Box>
          <Box className={sharedStyles.logo}>
            <img src={Logo} alt={'logo'} />
          </Box>
        </Box>
        <Stack className="w-10/12 space-y-5">
          <Typography className="!font-semibold !text-2xl" style={{ color: '#131515' }}>
            Verify Your <span className='capitalize'>{type}</span>
          </Typography>
          <Box className="flex justify-center">
          <Typography className="w-full md:w-4/5 !font-normal !text-2xl mx-auto mt-4">
            To ensure the security of your account, we’ve sent a 6-digit verification code
          </Typography>
          </Box>
        </Stack>
        <div className='otp_validation--container py-4' style={{ width: '70%' }}>
          <label className="w-full block font-medium text-lg text-left color-[#484848] mb-3">Verification Code</label>
          <Box className="flex flex-col items-center gap-4">
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={lockoutTimer > 0 || retryTimer > 0}
              inputClassName={`border-2 rounded-md !w-12 !h-12 lg:!w-14 lg:!h-14 text-center focus:outline-none ${
                shake ? 'border-red-500 otp-shake' : 'border-gray-300'
              }`}
            />
          </Box>
        </div>
        <Box className="flex items-center justify-between gap-4" style={{width: '70%'}}>
        <Box className="flex items-center gap-1">
            <Typography className="!font-medium !text-sm">
              Didn’t receive the code?
          </Typography>
          <Typography
            className={`!font-semibold !text-semibold !text-[#C44297] hover:underline cursor-pointer ${(retryTimer > 0 || lockoutTimer > 0 || resendTimer > 0) && 'pointer-events-none'}`}
            onClick={handleResendOtp}
          >
            Resend {resendTimer > 0 ? `in ${resendTimer}` : ''}
          </Typography>
          </Box>
          <Typography
            onClick={() => {
              setStep((prevStep) => ({
                ...prevStep,
                current: 'phoneValidation',
              }));
            }}
            className="!font-semibold !text-sm !text-[#C44297] hover:underline cursor-pointer">
            Change {type}
          </Typography>
        </Box>
        {errorMessage && (
          <Typography className="w-4/5 sm:w-3/5 !text-red-500 !text-sm mt-2 text-center">{errorMessage}</Typography>
        )}
        <Button
          variant="contained"
          size="large"
          sx={{
            width: '70%',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '20px',
          }}
          onClick={handleVerifyPhoneOtp}
          disabled={otp.length !== 6 || submitOtpRes.isLoading || lockoutTimer > 0 || retryTimer > 0}
        >
          {retryTimer > 0
            ? `Wait ${retryTimer}s`
            : lockoutTimer > 0
            ? `Locked for ${Math.ceil(lockoutTimer / 60)}m`
            : 'Next'}
        </Button>
      </Stack>
    </Box>
  );
}