import React from 'react';
import formatSeconds from 'utils/formatSeconds';



export default function Timer(props) {
  const [currentTime, setCurrentTime] = React.useState(props.time);
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (currentTime === 0) {
        props?.onTimeEnd();
        clearInterval(interval);
      } else {
        setCurrentTime(currentTime - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [currentTime]);

  return (
    <p className="text-[#572258] text-sm xl:text-base font-medium">
      {formatSeconds(currentTime)}
    </p>
  );
}
