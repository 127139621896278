import React from 'react';

import Button from 'components/Button';
import Modal from '../../components/Modal';
import useModal from '../../hook/useModal';

import AuthDetails from 'components/AuthDetails';
import Input from 'components/Input';
import Logo from 'components/Logo';
import { useFormik } from 'formik';

import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/min';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import queryString from 'query-string';
import TelephoneInput from 'components/TelephoneInput';
import { useCheckPhoneNumberExistsMutation } from 'store/apis/auth';
import { NotificationManager, Notifications } from 'react-notifications';

const validationSchema = Yup.object({
  // parentEmail: Yup.string().email('Invalid email').required('Required'),
  childFirstName: Yup.string().required('Your First name is required'),
  childLastName: Yup.string().required('Your Last name is required'),
  parentFirstName: Yup.string().required('Parents First name is required'),
  parentLastName: Yup.string().required('Parents Last name is required'),
  phone: Yup.string().required('Phone number is required'),
  dialCode: Yup.string().required('Country is required'),
});

export default function AskAGrownupModal() {
  const { closeModal, isOpen, modal } = useModal();
  const [checkIsPhoneAvailable, checkIsPhoneAvailableRes] =
    useCheckPhoneNumberExistsMutation();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      phone: '',
      dialCode: '+1',
      country: 'US',
      childFirstName: '',
      childLastName: '',
      parentFirstName: '',
      parentLastName: '',
    },
    validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        console.log('values', values);
        if (!isValidPhoneNumber(values.phone)) {
          setFieldError('phone', 'Invalid phone number');
          return;
        }

        /* const checkPhoneRes = await checkIsPhoneAvailable({
          phone: values.phone,
        }).unwrap();

        if (checkPhoneRes.data.isPhoneNumberAvailable === false) {
          NotificationManager.error('This phone number is already registered with us.');
          return;
        } */

        const parsedValue = parsePhoneNumber(values.phone);
        const query = queryString.stringify({
          ...values,
          phone: parsedValue.number,
          dialCode: parsedValue.countryCallingCode,
          birthDate: modal?.props?.birthDate,
        });

        closeModal();
        navigate(`/under13?${query}`);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Modal isOpen={isOpen('AskAGrownupModal')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_45rem] [--top:_2rem]  md:[--top:_10%]">
        <Modal.CloseButton />

        <form
          className="flex flex-col gap-5 py-4"
          onSubmit={formik.handleSubmit}
        >
          <Logo className="mx-auto w-48 lg:w-56" />

          <AuthDetails className="mt-4">
            <AuthDetails.Title>Ask a Grownup?</AuthDetails.Title>
            <AuthDetails.Description className="mt-4">
              You can still join us, but before you start using the camera or
              claiming your rewards, we'll need a grownup's okay.
            </AuthDetails.Description>
          </AuthDetails>

          <div className="grid md:grid-cols-2 gap-5">
            <Input
              placeholder="Your First Name"
              error={
                formik.touched.childFirstName && formik.errors.childFirstName
              }
              label={'Type your first name'}
              {...formik.getFieldProps('childFirstName')}
            />
            <Input
              placeholder="Your Last Name"
              error={
                formik.touched.childLastName && formik.errors.childLastName
              }
              label={'Type your last name'}
              {...formik.getFieldProps('childLastName')}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-5">
            <Input
              placeholder="Parent's First Name"
              error={
                formik.touched.parentFirstName && formik.errors.parentFirstName
              }
              label={'Type your parent first name'}
              {...formik.getFieldProps('parentFirstName')}
            />
            <Input
              placeholder="Parent's Last Name"
              error={
                formik.touched.parentLastName && formik.errors.parentLastName
              }
              label={'Type your parent last name'}
              {...formik.getFieldProps('parentLastName')}
            />
          </div>

          <TelephoneInput
            label="Phone Number"
            error={formik.touched.phone && formik.errors.phone}
            onChange={(value) => formik.setFieldValue('phone', value)}
            onCountryChange={({ callingCode, country }) => {
              formik.setFieldValue(
                'dialCode',
                callingCode?.toString()?.includes('+')
                  ? callingCode
                  : `+${callingCode}`,
              );
              formik.setFieldValue('country', country);
            }}
          />

          <Button
            className="max-w-none w-full mt-4"
            variant="primary"
            type="submit"
            isLoading={checkIsPhoneAvailableRes.isLoading}
          >
            Continue
          </Button>
        </form>
      </Modal.Content>
    </Modal>
  );
}
