import React, { useEffect, useState } from 'react';
import CardMockup from 'asset/card-mockup.jpg';
import AIChat from 'asset/splash/ai-chat.png';
import AITutor from 'asset/splash/ai-tutor.png';
import Quiz from 'asset/splash/quiz.png';
import OnboardingCard from './OnboardingCard';
import videoImage from '../assets/videos.png';


const carouselItems = [
  {
    image: require('../assets/learn-to-earn.svg').default,
    title: 'Learn to earn',
  },
  {
    image: require('../assets/ai-tutor.svg').default,
    title: 'AI tutor',
  },
  {
    image: videoImage,
    title: 'Videos',
  },
  {
    image: require('../assets/quizzes.svg').default,
    title: 'Quizzes',
  },
];

const WebSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    { name: 'Learn to Earn', img: CardMockup },
    { name: 'AI Tutor', img: AITutor },
    { name: 'Videos', img: AIChat },
    { name: 'Quizzes', img: Quiz },
  ];

  useEffect(() => {
    const handleNext = () => {
      setActiveIndex((prevIndex) =>
        prevIndex === items?.length - 1 ? 0 : prevIndex + 1,
      );
    };

    const autoplay = setInterval(() => {
      handleNext();
    }, 5000);
    return () => clearInterval(autoplay);
  }, [items?.length]);

  return (
    <div className="w-full flex flex-col items-center max-w-[calc(100vw-4rem)]">
      <div className="w-full flex overflow-hidden">
        {carouselItems.map((item, index) => (
          <OnboardingCard
            key={index}
            image={item.image}
            title={item.title}
            className={activeIndex === index ? 'w-2/5' : 'w-1/4'}
          />
        ))}
      </div>
    </div>
  );
};

export default WebSlider;
