import { useContext } from 'react';

import ModalContext from '../state/ModalContext';
import ModalProvider from '../state/ModalProvider';
import modals from '..';

const RenderModal = () => {
  const { state } = useContext(ModalContext);
  const Component = modals[state?.modal?.name];

  return Component ? <Component {...state?.modal?.props} /> : null;
};

const ModalWrapper = ({ children }) => {
  return (
    <ModalProvider>
      {children}
      <RenderModal />
    </ModalProvider>
  );
};

export default ModalWrapper;
