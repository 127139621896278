import React from 'react';

import useModal from '../../hook/useModal';
import Modal from '../../components/Modal';
import sad from './assets/sad.svg';
import AuthDetails from 'components/AuthDetails';
import Button from 'components/Button';

export default function QrCodeErrorModal() {
  const { closeModal, isOpen, modal } = useModal();


  return (
    <Modal isOpen={isOpen('QrCodeErrorModal')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_40rem]">
        <Modal.CloseButton />
        <div className="flex flex-col gap-8 py-4">
          <img
            src={sad}
            alt="sad"
            className="size-8 md:size-10 lg:size-12 mx-auto"
            size="sm"
            mx={'auto'}
          />

          <AuthDetails.Title>
            Oops! We couldn’t recognize your QR code.
          </AuthDetails.Title>

          <div className="grid gap-4">
            <p className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
              It seems there’s an issue with the code you scanned. Please
              ensure:
            </p>

            <ul className="list-disc list-inside">
              <li className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
                The QR code is clear and within the scanner frame.
              </li>
              <li className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
                You are using the correct QR code for G3MS.
              </li>
            </ul>

            <p className="text-base lg:text-base xl:text-lg 2xl:text-2xl text-black">
              If the issue persists, try again or contact our support team for
              assistance.
            </p>
          </div>

          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <Button
              className="max-w-none w-full"
              onClick={modal?.props?.onResend}
            >
              Contact Support
            </Button>
            <Button
              className="max-w-none w-full"
              variant="primary"
              onClick={closeModal}
            >
              Retry Scanning
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
