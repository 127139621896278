import AccountCreatedModal from './modals/AccountCreatedModal';
import AddSchoolModal from './modals/AddSchoolModal';
import AskAGrownupModal from './modals/AskAGrownupModal';
import LeadersPaymentWarning from './modals/LeadersPaymentWarning';
import InvalidOtpModal from './modals/OtpErrorModal';
import OtpSuccessModal from './modals/OtpSuccessModal';
import ParentPaymentWarning from './modals/ParentPaymentWarning';
import QrCodeErrorModal from './modals/QrCodeErrorModal';
import QrCodeSuccessModal from './modals/QrCodeSuccessModal';
import RequestParentApproval from './modals/RequestParentApproval';
import StudentPaymentWarning from './modals/StudentPaymentWarning';
import TeacherPaymentWarning from './modals/TeacherPaymentWarning';
import WarningModal from './modals/WarningModal';

const modals = {
  InvalidOtpModal,
  OtpSuccessModal,
  AccountCreatedModal,
  StudentPaymentWarning,
  TeacherPaymentWarning,
  ParentPaymentWarning,
  WarningModal,
  AskAGrownupModal,
  AddSchoolModal,
  RequestParentApproval,
  LeadersPaymentWarning,
  QrCodeSuccessModal,
  QrCodeErrorModal
};

export default modals;
