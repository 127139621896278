import React from 'react';
import cn from 'utils/cn';

export default function AuthPage({
  children,
  className = '',
  innerClassName = '',
}) {
  return (
    <section className="bg-[#D9E4E2] p-4 ">
      <div
        className={cn(
          'bg-[#E9F6F3] rounded-[2rem] overflow-x-hidden p-4 md:p-6 h-[calc(100vh-2rem)] grid grid-cols-1 md:grid-cols-2 gap-8',
        )}
      >
        <div className="w-full object-cover h-[calc(100vh-4rem)] md:h-[calc(100vh-5rem)] rounded-2xl overflow-hidden max-md:hidden">
          <img
            src="/auth-image.png"
            alt="img-discription"
            className="h-full w-full object-cover"
          />
        </div>
        <div
          className={cn(
            'bg-white rounded-[2rem] w-full overflow-hidden h-[calc(100vh-4rem)] md:h-[calc(100vh-5rem)] ',
          )}
        >
          <div
            className={cn(
              'bg-white py-4 px-8 rounded-[2rem] w-full object-cover  flex flex-col gap-8 overflow-y-auto max-md:content-center h-full',
              className,
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </section>
  );
}
