import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from '../../app/payment/styles.module.scss';

import LoadingOverlay from 'app/components/LoadingOverlay';
import ReferralOrPromo from 'app/payment/ReferralOrPromo';
import Modal from 'auth/components/modal/modal';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import { useSelector } from 'react-redux';
import {
  useGetSubscriptionsQuery,
  useIniTiatePaymentMutation,
} from 'store/apis/payment';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { selectUser } from 'store/Slice/authSlice';
import cn from 'utils/cn';
import getUserName from 'utils/getUserName';
import PaymentPlanCard from './components/PaymentCard';

const PaymentPlanList = () => {
  const [modalReferralOrPromoOpen, setModalReferralOrPromoOpen] =
    useState(false);
  const [modalPaymentConfirmationOpen, setPaymentConfirmationOpen] =
    useState(false);

  const [selectedPlan, setSelectedPlan] = useState(null);

  const { data, isLoading } = useGetSubscriptionsQuery();

  const { openModal } = useModal();

  const { data: userData, isLoading: loading } = useGetMyProfileQuery();

  const user = useSelector(selectUser)

  const [searchParams] = useSearchParams();

  const userId = user?.id || userData?.data?.id;

  const parentId = searchParams.get('parentId');

  const navigate = useNavigate();

  const [paymentRequest, paymentRequestResponse] = useIniTiatePaymentMutation();

  console.log('userIdd', userId);

  const initiatePayment = async (planId) => {
    try {
      if (!parentId && !userId) {
        openModal('WarningModal', {
          title: 'Warning',
          description: 'You need to login to proceed',
          onContinue: () => {
            navigate('/login');
          },
        });

        return;
      }
      const res = await paymentRequest({
        planId,
        userId: userId || parentId,
      }).unwrap();
      window.location.href = res.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading || loading) return <LoadingOverlay isLoading />;

  const userName = userData && getUserName(userData?.data);

  return (
    <>
      <div className={styles.pagePadding}>
        <div className={styles.upgradePlanContainer}>
          <header>
            <Logo />
            <h2>Hey {userName}! Let’s select the best G3MS plan for you.</h2>
          </header>

          <div className={styles.plansShadow}>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-14 sm:px-4 py-6 sm:py-10 max-w-xl lg:max-w-full w-full mx-auto">
              {data?.data
                ?.filter((item) => item?.packageType !== 'ADD_ON')
                .map((item, _index) => (
                  <PaymentPlanCard
                    key={_index}
                    interval={item.interval}
                    planName={item.name}
                    price={item.price}
                    features={item?.planFeatures?.map(
                      (feature) => feature.name,
                    )}
                    isRecommended={item.isRecommended}
                    description={item.description}
                    onSubscribe={() => {
                      initiatePayment(item.id);
                    }}
                    addition={data?.data.find(
                      (addition) =>
                        addition.packageType === 'ADD_ON' &&
                        addition.interval === item.interval,
                    )}
                    className={cn({
                      'lg:col-start-1': _index === 0,
                      'lg:col-start-2 lg:row-start-1 row-start-2': _index === 2,
                      'lg:col-start-3': _index === 1,
                    })}
                    onClick={() => setSelectedPlan(item)}
                    selected={selectedPlan?.id === item.id}
                  />
                ))}
            </div>

            <footer>
              <button onClick={() => setPaymentConfirmationOpen(true)}>
                Keep the FREE plan
              </button>
              <span onClick={() => setModalReferralOrPromoOpen(true)}>
                Got a Promo or Referral Code?
              </span>
            </footer>
          </div>
        </div>
      </div>

      <Modal
        open={modalReferralOrPromoOpen}
        setOpen={setModalReferralOrPromoOpen}
        overlayStyles={styles.modalOverlay}
        contentStyles={styles.modalContent}
      >
        <ReferralOrPromo setOpen={setModalReferralOrPromoOpen} />
      </Modal>

      <LoadingOverlay isLoading={paymentRequestResponse.isLoading || loading} />
    </>
  );
};

export default PaymentPlanList;
