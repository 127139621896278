import { useLocation } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import config from 'configs/config';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/store';
import HomeLogin from '../components/HomeLogin.png';
import Logo from '../components/logo.png';
import QR from './assets/qr.svg';
import SplashScreen from '../splash';
import sharedStyles from '../sharedStyle.module.scss';
import ProfileAvatar from './components/avatar';
import BirthDetail from './components/birth';
import ProfileDetail from './components/details';
import FreePlan from './components/freePlan';
import LoginUser from './components/login';
import Payment from './components/payment';
import Pricing from './components/pricing';
import ScanQR from './components/scanQR';
import Signup from './components/signup';
import UploadPicture from './components/uploadPicture';
import OtpValidation from './components/OtpValidation';
import PhoneNumberValidation from './components/PhoneNumberValidation';
import analytics from 'utils/analytics';

const Login = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const type = queryParams?.get('type');

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.authReducer);
  const [isUpgrade, setIsUpgrade] = useState(false);

  const [steps, setStep] = useState({
    current: 'home',
    underAge: false,
  });

  useEffect(() => {
    if (type == 'parent') {
      setStep({
        current: 'phoneOtp',
        phoneNumber: 0,
        verificationSid: '',
      });
    }
  }, [type]);

  useEffect(() => {
    if (user) {
      if (!user?.profileImage) {
        setStep((prevStep) => ({
          ...prevStep,
          current: user.isUnder13 ? 'avatar' : 'uploadPicture',
        }));
        return;
      }
      if (
        !user?.completedProfile &&
        !user?.schoolId &&
        user?.type !== 'super_admin'
      ) {
        setStep((prevStep) => ({
          ...prevStep,
          current: 'detail',
        }));
        return;
      }
      const token = localStorage.getItem('accessToken');
      switch (user.type) {
        case 'student':
          navigate('/app/student');
          break;
        case 'teacher':
          localStorage.clear();
          window.location.href = `${config.ADMIN_URL}/school/${user.school.id}?token=${token}`;
          break;
        case 'parent':
          localStorage.clear();
          window.location.href = `${config.ADMIN_URL}/super-admin/dashboard?token=${token}`;
          break;
        case 'school_leader':
          localStorage.clear();
          window.location.href = `${config.ADMIN_URL}/school-management/school/${user?.school.id}?token=${token}`;
          break;
        default:
          localStorage.clear();
          window.location.href = `${config.ADMIN_URL}/super-admin/dashboard?token=${token}`;
          break;
      }
    }
  }, [user, navigate]);

  useEffect(() => {
    analytics.logEvent('page_view', {
      page_path: window.location.pathname + '/' + steps.current,
    });
  }, [steps]);

  console.log(steps);

  return (
    <>
      {steps.current === 'login' && <LoginUser setStep={setStep} />}
      {steps.current === 'birth' && <BirthDetail setStep={setStep} />}
      {steps.current === 'splash' && <SplashScreen setStep={setStep} />}
      {steps.current === 'signup' && (
        <Signup
          steps={steps}
          setStep={setStep}
          phoneNumber={steps?.phoneNumber}
          type={steps?.type}
        />
      )}
      {steps.current === 'avatar' && <ProfileAvatar setStep={setStep} />}
      {steps.current === 'detail' && <ProfileDetail setStep={setStep} />}
      {steps.current === 'qr' && <ScanQR setStep={setStep} />}
      {steps.current === 'uploadPicture' && <UploadPicture setStep={setStep} />}
      {steps.current === 'phoneOtp' && (
        <OtpValidation
          setStep={setStep}
          type={'phone'}
          phoneNumber={steps?.phoneNumber}
          verificationSid={steps?.verificationSid}
        />
      )}
      {steps.current === 'emailOtp' && (
        <OtpValidation setStep={setStep} type={'email'} />
      )}
      {steps.current === 'phoneValidation' && (
        <PhoneNumberValidation setStep={setStep} />
      )}

      {steps.current === 'home' && (
        <Box className={`${sharedStyles.container}`}>
          <Stack className={`${sharedStyles.studentsContainer}`}>
            <Box className={sharedStyles.imageLeft}>
              <img src={HomeLogin} alt="img-discription" />
            </Box>
          </Stack>
          <Stack className={`${sharedStyles.loginContainer}`}>
            <Box className={sharedStyles.logo}>
              <img src={Logo} alt={'logo'} />
            </Box>
            <Typography className="!font-medium !text-2xl">
              Already have an account?{' '}
              <span
                style={{
                  color: '#C73E9D',
                  fontWeight: '700',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  setStep((prevStep) => ({
                    ...prevStep,
                    current: 'login',
                  }))
                }
              >
                Login
              </span>{' '}
            </Typography>
            <Typography
              className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
              style={{ color: '#131515' }}
            >
              Do you have a QR code?
            </Typography>
            <button
              className={
                'shadow-2xl p-8 grid gap-8 place-items-center rounded-3xl'
              }
              onClick={() =>
                setStep((prevStep) => ({
                  ...prevStep,
                  current: 'qr',
                }))
              }
            >
              <img src={QR} alt="qr" className="size-32" />
              <h2 className="font-semibold text-2xl">Scan QR Code</h2>
            </button>
            <button
              className={
                'border border-[#C73E9D] text-[#C73E9D] px-4 py-3 rounded-xl font-medium hover:bg-[#C73E9D] hover:text-white transition-all duration-200 ease-in-out w-full max-w-[15rem]'
              }
              onClick={() =>
                setStep((prevStep) => ({ ...prevStep, current: 'birth' }))
              }
            >
              Sign Up
            </button>
          </Stack>
        </Box>
      )}
      {steps.current === 'pricing' && (
        <Pricing
          isUpgrade={isUpgrade}
          setIsUpgrade={setIsUpgrade}
          setStep={setStep}
        />
      )}
      {steps.current === 'payment' && <Payment setStep={setStep} />}
      {steps.current === 'freePlan' && (
        <FreePlan
          isUpgrade={isUpgrade}
          setIsUpgrade={setIsUpgrade}
          setStep={setStep}
        />
      )}
    </>
  );
};

export default Login;
