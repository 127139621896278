import React from 'react';

import { auth } from 'api/firebase';
import Button from 'components/Button';
import useHandleUserNavigation from 'hooks/useHandleUserNavigation';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { useGetMyProfileQuery } from 'store/apis/profile';
import getUserName from 'utils/getUserName';
import animation from '../../../../asset/lottie/bot-animation.json';
import Modal from '../../components/Modal';
import useModal from '../../hook/useModal';

export default function LeadersPaymentWarning() {
  const { closeModal, isOpen, modal } = useModal();
  const { data, isLoading } = useGetMyProfileQuery();

  const { loginUser } = useHandleUserNavigation();



  const navigate = useNavigate();

  const userName = React.useMemo(() => {
    if (data?.data) {
      return getUserName({
        firstName: data?.data?.name,
        lastName: data?.data?.lastname,
        username: data?.data?.username,
        type: data?.data?.type ?? 'student',
      });
    }
    return '';
  }, [data]);

  return (
    <Modal isOpen={isOpen('LeadersPaymentWarning')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_45rem] [--top:_5%]">
        {/* <Modal.CloseButton /> */}
        <div className="flex flex-col gap-4 py-4">
          <Lottie animationData={animation} className="mx-auto w-48 lg:w-56" />

          <div className="grid gap-4">
            <h1 className="text-[#2C3E50] font-bold text-lg xl:text-xl text-center mx-auto">
              🎉 Thank you for completing your profile, 🎉 {userName}!
            </h1>

            <p className="text-sm md:text-base xl:text-lg  text-black text-center mx-auto max-w-[45ch]">
              You’re one step closer to helping your kid unlock their learning
              potential.
            </p>
          </div>

          <h1 className="text-[#2C3E50] font-bold text-lg xl:text-xl text-center mx-auto">
            ✨ Empower Your Students with Earl Adopter Program ✨
          </h1>

          <ul className="text-sm xl:text-base text-black space-y-2">
            <li>
              📊 Access real-time data and trends in the Admin Dashboard to
              track student progress.
            </li>
            <li>
              🔧 Simplify RTI plans with automated insights and customized
              learning paths.
            </li>
            <li>
              📚 Assign state-aligned curriculum in Math, ELA, Science, and
              Social Studies.
            </li>
            <li>
              🎓 Prepare students for high school and beyond with test prep
              tools (ISEE, SHSAT, SAT/ACT).
            </li>
            <li>
              🚀 Engage students with coding challenges and future-ready skills
              development.
            </li>
          </ul>

          <h1 className="text-[#2C3E50] font-bold text-lg xl:text-xl text-center mx-auto">
            🌟 Ready to Enhance Your Teaching? 🌟
          </h1>

          <div className="grid gap-4 lg:grid-cols-2">
            <Button
              className="max-w-none w-full"
              variant="primary"
              onClick={async () => {
                closeModal();
                const token = await auth.currentUser?.getIdToken();
                loginUser(data?.data, token);
              }}
              isLoading={isLoading}
            >
              Try a Demo
            </Button>
            <Button
              className="max-w-none w-full"
              variant="primary"
              onClick={async () => {
                closeModal();
                const token = await auth.currentUser?.getIdToken();
                loginUser(data?.data, token);
              }}
              isLoading={isLoading}
            >
              Join Our Early Adopter Program
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
