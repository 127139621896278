import {
  Box,
  CircularProgress,
  Stack,
  Typography
} from '@mui/material';
import axios from 'api/axios';
import { auth } from 'api/firebase';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';
import { setUserDetails } from 'store/Slice/authSlice';
import { useAppDispatch } from 'store/store';
import analytics from 'utils/analytics';
import Arrow from '../../../components/Arrow-Left.png';
import QrBorder from '../../../components/QrBorder.png';
import sharedStyles from '../../../sharedStyle.module.scss';

export default function ScanQR({ setStep }) {
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [info, setInfo] = useState({
    ipAddress: '',
    userAgent: '',
    macAddress: 'Unavailable in browser',
  });

  useEffect(() => {
    const userAgent = navigator?.userAgent;

    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        setInfo((prev) => ({
          ...prev,
          userAgent,
          ipAddress: data.ip,
        }));
      })
      .catch((error) => console.error('Error fetching IP address:', error));

  }, []);

  useEffect(() => {
    if (!data) return;
    setLoading(true);
    setError('');
    setSuccess('');
    axios
      .post('/auth/scan/qrcode', {
        qrCode: data,
        userAgent: info?.userAgent,
        ipAddress: info?.ipAddress,
        macAddress: info?.macAddress
      })
      .then((response) => {
        if (response.status === 201) {
          setSuccess('Login Successfully');
          console.log('response', response?.data);
          NotificationManager.success(response?.data?.data?.scanResult, 'Success', 3000);

         const resUser = response?.data?.data?.user
         const resTempUser = response?.data?.data?.tempUser

         console.log('resUser', resUser, response?.data)
         if(resUser){
          // Construct access token
         console.log('resUser', resUser)
          auth
            .signInWithCustomToken(response.data.data.customToken)
            .then(async (userCredential) => {
              const accessToken = await userCredential.user.getIdToken();
              const refreshToken = await userCredential.user.refreshToken;
              localStorage.setItem('accessToken', accessToken);
              localStorage.setItem('refreshToken', refreshToken);
              localStorage.setItem('uuid', response?.data?.data?.tempUser?.id);
              localStorage.setItem('currentUser', response.data.data.user.type);
              localStorage.setItem(
                'userData',
                JSON.stringify(response.data.data.user),
              );
              dispatch(setUserDetails(response.data.data.user));
              analytics.logEvent('scan_login', {
                user_id: response?.data?.data?.tempUser?.id,
              });
            })
            .catch((error) => {
              console.error(error);
              analytics.logEvent('scan_login_error', { error: error.message });
            });
         }else{
          setStep((prevStep) => ({
            ...prevStep,
            current: 'birth',
          }))
         }
        }


      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError(error.response.data.message[0]);
        analytics.logEvent('scan_login_error', { error: error.message });
      });
  }, [dispatch, data, info]);

  return (
    <Box className={sharedStyles.qrContainer}>
      <Box className={sharedStyles.arrowFloat}>
        <img
          src={Arrow}
          alt="img-discription"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setStep((prevStep) => ({
              ...prevStep,
              current: 'home',
            }));
          }}
        />
      </Box>
      <Box className={sharedStyles.avatar}>
        {/* <img src={QRCode} className={sharedStyles.qrCode} alt={'logo'} /> */}
        <video id="qr-video" className={sharedStyles.qrCode}></video>
        <img src={QrBorder} alt={'logo'} />
      </Box>
      <QrReader
        onResult={(result) => {
          if (!!result) {
            setData(result.text);
          }
        }}
        style={{ width: '100%' }}
        videoId="qr-video"
        videoStyle={{ width: '90%', height: '100%' }}
        constraints={{
          facingMode: 'user',
          aspectRatio: { ideal: 1 },
        }}
      />
      <Typography
        style={{
          color: error ? 'red' : 'green',
          fontSize: '16px',
          display: error ? 'block' : 'none',
        }}
      >
        {error || success}
      </Typography>
      <Stack sx={{ '& .MuiTypography-root': { textAlign: 'center' } }}>
        <Typography
          className="!font-semibold"
          style={{ color: '#131515', fontSize: '32px' }}
        >
          Scan QR code to sign in
        </Typography>
        <CircularProgress
          style={{
            color: '#131515',
            margin: 'auto',
            visibility: loading ? 'visible' : 'hidden',
          }}
        />
      </Stack>
    </Box>
  );
}
