import React from 'react';

import sharedStyles from '../../../sharedStyle.module.scss';

import Logo from '../../../components/logo.png';

import { isEmail } from '@core/utils/validators';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import {
  appleProvider,
  auth,
  classLinkProvider,
  googleProvider,
} from 'api/firebase';
import { useEffect, useState } from 'react';
import { buildStyles } from 'react-circular-progressbar';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import {
  setEmail,
  setPassword,
  setPhone,
  setUsername,
} from 'store/Slice/registerSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import analytics from 'utils/analytics';
import axios from '../../../../api/axios';
import { setUserDetails } from '../../../../store/Slice/authSlice';
import AppleIcon from '../../../components/Apple.png';
import Arrow from '../../../components/Arrow-Left.png';
import ClassLinkIcon from '../../../components/Classlink.png';
import GoogleIcon from '../../../components/Google.png';
import HomeLogin from '../../../components/StudentsSecond.png';
import ParentsDialog from '../dialog';

export default function Signup({ steps, setStep, phoneNumber }) {

  const dispatch = useAppDispatch();
  const { email, phone, password, date, username, parentEmail, parentPhone } =
    useAppSelector((state) => state.registerReducer);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.authReducer);

  function signUpWithGoogle() {
    signInWithProvider(googleProvider);
  }

  function signInWithApple() {
    signInWithProvider(appleProvider);
  }

  function classLink() {
    signInWithProvider(classLinkProvider);
  }

  function onSignUp() {
    // check if email is valid regex
    if (!steps.underAge && !isValidPhoneNumber(phoneNumber)) {
      console.log('Phone', phoneNumber);
      NotificationManager.error('Please enter a valid phone number', 'Error');
      return;
    }
    if (!steps.underAge && !isEmail(email)) {
      NotificationManager.error('Please enter a valid email', 'Error');
      return;
    }
    if (steps.underAge && !username) {
      NotificationManager.error('Please enter a username', 'Error');
      return;
    }

    // Check if password match with confirm password
    if (password !== confirmPassword) {
      NotificationManager.error('Password does not match', 'Error');
      return;
    }

    // Make api request
    let endpoint = '/auth/user/register';
    let requestData = {
      email,
      phone: steps.underAge ? parentPhone : phoneNumber,
      password,
      confirmPassword,
      dateOfBirth: `${date.year}-${date.month}-${date.day}`,
    };
    if (steps.underAge) {
      endpoint = '/auth/child/register';
      requestData = {
        username,
        parentPhone: steps.dialCode + steps.phone,
        dateOfBirth: `${date.year}-${date.month}-${date.day}`,
        password,
        confirmPassword,
        parentFirstName: steps?.parentFirstName,
        parentLastName: steps?.parentLastName,
        childFirstName: steps?.childFirstName,
        childLastName: steps?.childLastName,
      };
    }

    analytics.logEvent('sign_up', {
      email,
      phone: steps.underAge ? parentPhone : phoneNumber,
      username,
      dateOfBirth: `${date.year}-${date.month}-${date.day}`,
    });

    createUser(endpoint, requestData);
  }

  function signInWithProvider(provider) {
    auth.signOut().then(() => {
      // clear cookies
      console.log('clear cookies');
      document.cookie.split(';').forEach(function (c) {
        console.log('clear cookie:', c);
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
      });
    });
    setLoading(true);
    auth
      .signInWithPopup(provider)
      .then(async (user) => {
        await onSignIn(user, 'social');
      })
      .catch(onError);
  }

  function onError(error) {
    if (
      !['auth/cancelled-popup-request', 'auth/popup-closed-by-user'].includes(
        error.code,
      )
    ) {
      setLoading(false);
      analytics.logEvent('sign_up_error', {
        error: error.message,
      });
      NotificationManager.error(error.message, 'Error', 3000);
    }
  }

  async function onSignIn(userCredential, provider = 'email') {
    const token = await userCredential.user.getIdToken();
    axios
      .post('/auth/firebase/register', {
        firebaseAccessToken: token,
        parentEmail: steps.underAge ? parentEmail : undefined,
        parentPhone: steps.underAge ? parentPhone : undefined,
        dateOfBirth: `${date.year}-${date.month}-${date.day}`,
      })
      .then(async (res) => {
        NotificationManager.success(userCredential.user.displayName, 'Success');
        const user = res?.data?.data?.user;
        console.log(
          '================/auth/firebase/register===============',
          res,
        );

        localStorage.setItem('uuid', user?.id);
        localStorage.setItem('userData', JSON.stringify(user));
        localStorage.setItem(
          'accessToken',
          await userCredential.user.getIdToken(true),
        );
        dispatch(setUserDetails(user));
        analytics.logEvent('sign_up', {
          email: user.email,
          phone: user.phone,
          username: user.username,
          dateOfBirth: user.dateOfBirth,
        });
      })
      .catch((err) => {
        console.log('err', err);
        NotificationManager.error(err?.response?.data?.message, 'Error', 3000);
        analytics.logEvent('sign_up_error', {
          error: err,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function createUser(endpoint, data) {
    setLoading(true);
    axios
      .post(endpoint, data)
      .then((response) => {
        setLoading(false);
        if (response.status === 201) {
          NotificationManager.success('Registration', 'Success');

          // Construct access token
          auth
            .signInWithCustomToken(response.data.data.customToken)
            .then(async (userCredential) => {
              const accessToken = await userCredential.user.getIdToken();
              const refreshToken = await userCredential.user.refreshToken;
              localStorage.setItem('accessToken', accessToken);
              localStorage.setItem('refreshToken', refreshToken);
              localStorage.setItem('uuid', response.data.data.user.id);
              localStorage.setItem('fuid', userCredential.user.uid);
              localStorage.setItem('currentUser', response.data.data.user.type);
              localStorage.setItem('resendEmailOtpCount', '1');
              localStorage.setItem(
                'userData',
                JSON.stringify(response.data.data.user),
              );

              dispatch(setUserDetails(response.data.data.user));
              if (!steps.underAge) {
                setStep((prevStep) => ({
                  ...prevStep,
                  current: 'emailOtp',
                }));
              }
            })
            .catch((error) => {
              console.error(error);
              analytics.logEvent('sign_up_error', {
                error: error,
              });
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data?.message) {
          if (typeof error?.response?.data?.message === 'string') {
            NotificationManager.error(
              error?.response?.data?.message,
              'Error',
              3000,
            );
          } else {
            for (let key in error?.response?.data?.message) {
              NotificationManager.error(
                error?.response?.data?.message[key],
                'Error',
                3000,
              );
            }
          }
        }
      });
  }

  useEffect(() => {
    if (phoneNumber) {
      setPhone(phoneNumber);
    }
  }, [phoneNumber]);

  return (
    <Box className={`${sharedStyles.container}`}>
      <Stack className={`${sharedStyles.studentsContainer}`}>
        <Box className={sharedStyles.arrow}>
          <img
            src={Arrow}
            alt="img-discription"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setStep((prevStep) => ({
                ...prevStep,
                current: 'birth',
              }));
            }}
          />
        </Box>
        <Box className={sharedStyles.imageLeft}>
          <img src={HomeLogin} alt="img-discription" />
        </Box>
      </Stack>
      <Stack
        className={`${sharedStyles.loginContainer} ${sharedStyles.signupContainer} ${sharedStyles.signupContainerDetails}`}
      >
        <Box className={sharedStyles.logoWrapper}>
          <Box className={sharedStyles.arrow}>
            <img
              src={Arrow}
              alt="img-discription"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setStep((prevStep) => ({
                  ...prevStep,
                  current: 'birth',
                }));
              }}
            />
          </Box>
          <Box className={sharedStyles.logo}>
            <img src={Logo} alt={'logo'} />
          </Box>
        </Box>
        <Typography className="!font-medium sm:!text-lg md:!text-xl lg:!text-2xl">
          Already have an account?{' '}
          <span
            style={{ color: '#C73E9D', fontWeight: '600', cursor: 'pointer' }}
            onClick={() =>
              setStep((prevStep) => ({
                ...prevStep,
                current: 'login',
              }))
            }
          >
            Login
          </span>{' '}
        </Typography>
        <Stack className="w-10/12">
          <Typography
            className="!font-semibold text-lg sm:!text-lg md:!text-xl lg:!text-2xl"
            style={{ color: '#131515' }}
          >
            Create a G3MS Account
          </Typography>
          <Typography
            className="w-9/12 sm:w-10/12 md:w-3/5 block !text-black !text-center !text-lg sm:!text-lg md:!text-xl lg:!text-xl !mx-auto !my-3 "
            style={{ color: '#979C9E' }}
          >
            Give your students the support they need to close learning gaps.
          </Typography>
        </Stack>
        <Button
          variant={'outlined'}
          size="small"
          sx={{
            textTransform: 'capitalize',
            fontWeight: 500,
          }}
          className={
            'primary_bg_btn  w-[80%] sm:w-[35%] !text-2xl sm:!text-2xl'
          }
          onClick={() =>
            setStep((prevStep) => ({
              ...prevStep,
              current: 'qr',
            }))
          }
        >
          Scan QR Code
        </Button>
        {/* <CircularProgress
          size={30}
          style={{
            display: loading ? 'block' : 'none',
            margin: 'auto',
            marginTop: '20px',
          }}
          styles={buildStyles({
            pathColor: '#C73E9D',
          })}
        /> */}
        <Stack
          className="w-[80%] sm:w-[60%]"
          gap={4}
          sx={{
            '& .MuiOutlinedInput-input': {
              fontSize: '18px',
              fontWeight: 500,
            },
            mt: '20px',
          }}
        >
          {steps.underAge && (
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '5.5px 14px',
                  paddingTop: '20px',
                },
              }}
            >
              <InputLabel
                htmlFor="my-textfield"
                style={{
                  marginTop: '-10px',
                  fontWeight: 600,
                  fontSize: '12px',
                  color: '#130F26',
                }}
              >
                Username
              </InputLabel>
              <TextField
                placeholder="Username"
                id="my-textfield"
                variant="outlined"
                onChange={(e) => dispatch(setUsername(e.target.value))}
              />
            </FormControl>
          )}
          {!steps.underAge && !phoneNumber && (
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '5.5px 14px',
                  paddingTop: '20px',
                },
              }}
            >
              <InputLabel
                htmlFor="my-textfield"
                style={{
                  marginTop: '-10px',
                  fontWeight: 600,
                  fontSize: '12px',
                  color: '#130F26',
                }}
              >
                Text number
              </InputLabel>
              <TextField
                placeholder="+12013514000"
                id="my-textfield"
                size="small"
                variant="outlined"
                onChange={(e) => dispatch(setPhone(e.target.value))}
              />
            </FormControl>
          )}
          {!steps.underAge && (
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '5.5px 14px',
                  paddingTop: '20px',
                },
              }}
            >
              <InputLabel
                htmlFor="my-textfield"
                style={{
                  marginTop: '-10px',
                  fontWeight: 600,
                  fontSize: '12px',
                  color: '#130F26',
                }}
              >
                Email
              </InputLabel>
              <TextField
                placeholder="test@example.com"
                id="my-textfield"
                size="small"
                variant="outlined"
                onChange={(e) => dispatch(setEmail(e.target.value))}
              />
            </FormControl>
          )}
          <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-2">
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '5.5px 14px',
                  paddingTop: '20px',
                },
              }}
            >
              <InputLabel
                htmlFor="my-textfield"
                style={{
                  marginTop: '-10px',
                  fontWeight: 600,
                  fontSize: '12px',
                  color: '#130F26',
                }}
              >
                Password
              </InputLabel>
              <TextField
                placeholder="********"
                type={showPassword ? 'text' : 'password'}
                id="my-textfield"
                variant="outlined"
                onChange={(e) => dispatch(setPassword(e.target.value))}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-input': {
                  padding: '5.5px 14px',
                  paddingTop: '20px',
                },
              }}
            >
              <InputLabel
                htmlFor="my-textfield"
                style={{
                  marginTop: '-10px',
                  fontWeight: 600,
                  fontSize: '12px',
                  color: '#130F26',
                }}
              >
                Confirm Password
              </InputLabel>
              <TextField
                placeholder="********"
                type={showPassword ? 'text' : 'password'}
                id="my-textfield"
                variant="outlined"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
          </div>
        </Stack>
        <Box className="flex items-center justify-start w-[60%] -mb-5 -ml-5">
          <Checkbox
            value={showPassword}
            onChange={() => setShowPassword(!showPassword)}
          />
          <Typography
            className="!font-normal"
            style={{ color: '#292929', fontSize: '14px' }}
          >
            Show password
          </Typography>
        </Box>
        <Button
          variant={'contained'}
          size="small"
          sx={{
            width: '60%',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '20px',
            borderWidth: '2px',
            mt: '20px',
          }}
          className={'primary_bg_btn'}
          onClick={onSignUp}
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Sign Up'}
        </Button>
        <Box className={sharedStyles.orField}>
          <Box className={sharedStyles.line}></Box>
          <Typography>OR</Typography>
          <Box className={sharedStyles.line}></Box>
        </Box>
        <Box sx={{ maxWidth: '794px', margin: '0 auto' }}>
          <Box className={sharedStyles.buttowrap} sx={{ maxHeight: '100px' }}>
            <Box className={sharedStyles.button}>
              <button
                className={sharedStyles.loginButton}
                onClick={() => signInWithApple()}
                style={{
                  backgroundColor: '#FB1644',
                  marginBottom: '10px',
                }}
              >
                <Typography className={sharedStyles.applink}>
                  <img src={AppleIcon} alt={'apple logo icon'} />
                </Typography>
              </button>
              <Typography className={sharedStyles.appstatus}>
                Sign up with <span style={{ color: '#000' }}>Apple ID</span>
              </Typography>
            </Box>
            <Box className={sharedStyles.button}>
              <button
                className={sharedStyles.loginButton}
                onClick={() => signUpWithGoogle()}
                style={{
                  backgroundColor: '#DF4B3C',
                  marginBottom: '10px',
                }}
              >
                <Typography className={sharedStyles.applink}>
                  <img src={GoogleIcon} alt={'google logo icon'} />
                </Typography>
              </button>
              <Typography className={sharedStyles.appstatus}>
                Sign up with <span style={{ color: '#000' }}>Google</span>
              </Typography>
            </Box>
            <Box className={sharedStyles.button}>
              <button
                className={sharedStyles.loginButton}
                style={{
                  backgroundColor: '#1BA0ED',
                  marginBottom: '10px',
                }}
                onClick={() => classLink()}
              >
                <Typography className={sharedStyles.applink}>
                  <img src={ClassLinkIcon} alt={'class link logo icon'} />
                </Typography>
              </button>
              <Typography className={sharedStyles.appstatus}>
                Sign up with <span style={{ color: '#000' }}>Class Link</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Stack>
      <ParentsDialog />
    </Box>
  );
}
