import LoadingOverlay from 'app/components/LoadingOverlay';
import Button from 'components/Button';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import React from 'react';
import {
  useGetMyProfileQuery,
  useUpdateProfileImageMutation,
} from 'store/apis/profile';
import analytics from 'utils/analytics';
import cn from 'utils/cn';

function UploadAvatar() {
  const { openModal } = useModal();


  const imageInputRef = React.useRef(null);

  const { data: myProfileData } = useGetMyProfileQuery();



  const [selectedAvatar, setSelectedAvatar] = React.useState(null);

  const [updateProfileImage, updateProfileImageResult] =
    useUpdateProfileImageMutation();

  const updateProfileImageHandler = async () => {
    try {
      if (!selectedAvatar) {
        openModal('WarningModal', {
          title: 'Error',
          message: 'Please select an avatar',
        });

        return;
      }

      const formData = new FormData();
      formData.append('profileImage', selectedAvatar);

      const res = await updateProfileImage(formData).unwrap();

      analytics.logEvent('profile_image_updated', {
        userId: res.data.id,
        profileImage: res.data.profileImage,
      });

      const user = myProfileData?.data;

      if (
        user.type === 'private_tutor' ||
        user.type === 'teacher' ||
        user.type === 'parent'
      ) {
        openModal('ParentPaymentWarning');
        return;
      }

      if (user.type !== 'student') {
        openModal('TeacherPaymentWarning');
        return;
      }

      openModal('StudentPaymentWarning');
    } catch (err) {
      openModal('WarningModal', {
        title: 'Error',
        message: err.data?.message || 'An error occurred',
      });
    }
  };

  return (
    <section className="bg-[#D9E4E2] grid place-items-center min-h-screen">
      <div className="bg-white w-full max-w-[40rem] mx-auto p-5 rounded-lg gap-10 grid place-items-center h-full max-h-[45rem]">
        <Logo />
        <div className="grid place-items-center gap-5">
          <img
            src={
              selectedAvatar
                ? URL.createObjectURL(selectedAvatar)
                : '/images/common2.png'
            }
            className="size-24 lg:size-32 object-cover rounded-full xl:size-48 mx-auto my-4"
            alt={'avatar'}
          />

          <h1
            className={cn(
              'font-bold text-center text-lg lg:text-2xl xl:text-3xl text-[#131515]',
            )}
          >
            Your Profile Picture
          </h1>
          <p className="text-center text-sm lg:text-base xl:text-lg 2xl:text-xl text-[#979C9E]">
            Upload or Take a profile picture.
          </p>
        </div>
        <input
          type="file"
          ref={imageInputRef}
          className="hidden"
          accept="image/*"
          onChange={(e) => {
            setSelectedAvatar(e.target.files[0]);
          }}
        />
        <div className="space-y-5 w-full">
          <Button
            onClick={() => {
              imageInputRef.current.click();
            }}
            isLoading={updateProfileImageResult.isLoading}
            className="max-w-[25rem] mx-auto"
          >
            {selectedAvatar ? 'Retake' : 'Take a photo'}
          </Button>

          {selectedAvatar && (
            <Button
              onClick={updateProfileImageHandler}
              variant="primary"
              className="max-w-[25rem] mx-auto"
            >
              <span>Save</span>
            </Button>
          )}
        </div>
        <LoadingOverlay
          isLoading={updateProfileImageResult.isLoading}
        />
      </div>
    </section>
  );
}

export default UploadAvatar;
