import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/store';

import config from 'configs/config';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { useDispatch } from 'react-redux';
import axios from '../../api/axios';
import { setUserDetails } from '../../store/Slice/authSlice';
import { options } from '../components/selectType';

const ClassLinkRedirect = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  var regex = new RegExp(options.join('|'), 'i');
  var isAvailable = regex.test(params.type);

  const [loading, setLoading] = useState(false);


  function onSignIn(userCredential, provider = 'email', type = 'student') {
    axios
      .post('/users/login', {
        email: userCredential.user.email,
        firebaseId: userCredential.user.uid,
        userType: type.toLowerCase(),
      })
      .then((res) => {
        NotificationManager.success(userCredential.user.displayName, 'Success');
        const token = res.data.token;
        dispatch(setUserDetails(res.data));
        console.log('res', res);
        switch (res.data.type) {
          case 'student':
            if (res?.data?.school) {
              localStorage.setItem('uuid', res.data.id);
              localStorage.setItem('userData', JSON.stringify(res.data));
              navigate('/app/student');
            } else {
              localStorage.setItem('uuid', res.data.id);
              localStorage.setItem('currentUser', type.toLowerCase());
              localStorage.setItem('currentUserData', JSON.stringify(res.data));
              navigate(
                `/auth/complete_profile?type=${type.toLowerCase()}&email=${
                  res?.data?.email
                }`,
              );
            }
            break;
          case 'teacher':
            if (res?.data?.school) {
              window.location.href = `${config.ADMIN_URL}/school/${res.data.school.id}?token=${token}`;
            } else {
              localStorage.setItem('uuid', res.data.id);
              localStorage.setItem('currentUser', type.toLowerCase());
              localStorage.setItem('currentUserData', JSON.stringify(res.data));
              navigate(
                `/auth/complete_profile?type=${type.toLowerCase()}&email=${
                  res?.data?.email
                }`,
              );
            }
            break;
          case 'parent':
            if (res?.data) {
              window.location.href = `${config.ADMIN_URL}/super-admin/dashboard?token=${token}`;
            } else {
              localStorage.setItem('uuid', res.data.id);
              localStorage.setItem('currentUser', type.toLowerCase());
              localStorage.setItem('currentUserData', JSON.stringify(res.data));
              navigate(
                `/auth/complete_profile?type=${type.toLowerCase()}&email=${
                  res?.data?.email
                }`,
              );
            }
            break;
          case 'school_leader':
            if (res?.data?.school) {
              window.location.href = `${config.ADMIN_URL}/school-management/school/${res?.data?.school.id}?token=${token}`;
            } else {
              localStorage.setItem('uuid', res.data.id);
              localStorage.setItem('currentUser', type.toLowerCase());
              localStorage.setItem('currentUserData', JSON.stringify(res.data));
              navigate(
                `/auth/complete_profile?type=${type.toLowerCase()}&email=${
                  res?.data?.email
                }`,
              );
            }
            break;
          // case 'super_admin':
          //   window.location.href = `${config.ADMIN_URL}/super-admin/school-management/school?token=${token}`;
          //   break;
          default:
            window.location.href = `${config.ADMIN_URL}/super-admin/dashboard?token=${token}`;
            break;
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.error == 'Bad Request' &&
          provider == 'social'
        ) {
          onRegister(userCredential);
        } else {
          NotificationManager.error(
            err?.response?.data?.message,
            'Error',
            3000,
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onRegister(userCredential, type = 'student') {
    axios
      .post('/user', {
        email: userCredential.user.email,
        firebaseId: userCredential.user.uid,
        type: type.toLowerCase(),
      })
      .then((response) => {
        console.log('dat', response.data);
        setLoading(false);

        if (response.status == 201) {
          NotificationManager.success(response.data.data.email, 'Success');
          localStorage.setItem('uuid', response.data.data.id);
          localStorage.setItem('currentUser', type.toLowerCase());
          localStorage.setItem(
            'currentUserData',
            JSON.stringify(response.data.data),
          );
          navigate(
            `/auth/complete_profile?type=${type.toLowerCase()}&email=${
              response.data.data.email
            }`,
          );
        }
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error('error', 'Error', 3000);
      });
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    const firebaseId = queryParameters.get('firebaseId');
    const email = queryParameters.get('email');
    if (firebaseId && email) {
      console.log('DDDDDDDDDDDDDDDDDDD', {
        firebaseId,
        email,
      });

      console.log({
        user: { email, uid: firebaseId },
      });

      onSignIn({ user: { email, uid: firebaseId } });
      //Send this to login function

      return;
    }
  }, []);

  return (
    <>
      {/* <RegisterPage typeParam={'student'} /> */}
      {/* {canRegister && <RegisterPage typeParam={params.type} />} */}
      {/* {!canRegister && <SelectTypePage />} */}
    </>
  );
};

export default ClassLinkRedirect;
