import React from 'react';

import AuthDetails from 'components/AuthDetails';
import AuthPage from 'components/AuthPage';
import Button from 'components/Button';
import Input from 'components/Input';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useSendEmailOtpMutation } from 'store/apis/otp';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
});

export default function EmailValidation() {
  const navigate = useNavigate();

  const { openModal } = useModal();

  const [sendEmailOtp, emailOtpRes] = useSendEmailOtpMutation();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await sendEmailOtp({ email: values.email });
        navigate('/otp-validation?type=email&email=' + values.email);
      } catch (error) {
        openModal({
          title: 'Error',
          description: error?.data?.message || 'Something went wrong',
        });
      }
    },
  });

  const handleSendPhoneOtp = async () => {};

  return (
    <AuthPage className="gap-4 content-center">
      <Logo className="w-32 mb-4" />
      <AuthDetails>
        <AuthDetails.TwoText
          leftText="Already have an account?"
          rightText="Login"
          rightTextUrl="/login"
        />
        <AuthDetails.Title>We need your email address</AuthDetails.Title>
        <AuthDetails.Description>
          To personalize your expetrience and link your account, we need your
          email. This will help us send important updates about your child’s
          progress.
        </AuthDetails.Description>
      </AuthDetails>
      <div className=" grid gap-y-3 w-full">
        <Input
          label="Email"
          placeholder="Enter your email address"
          type="email"
          error={formik.touched.email && formik.errors.email}
          {...formik.getFieldProps('email')}
        />
      </div>
      <Button
        variant={'primary'}
        onClick={handleSendPhoneOtp}
        className={'max-w-[25rem] mt-4 mx-auto'}
        isLoading={emailOtpRes.isLoading}
      >
        Next
      </Button>
    </AuthPage>
  );
}
