import React from 'react';

import Button from 'components/Button';
import Modal from '../../components/Modal';
import useModal from '../../hook/useModal';

import AuthDetails from 'components/AuthDetails';
import Input from 'components/Input';
import Logo from 'components/Logo';
import { useFormik } from 'formik';

import { Select, Textarea } from '@mantine/core';
import { ChevronDown } from 'mdi-material-ui';
import { useAddTemporarySchoolMutation } from 'store/apis/school';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('School name is required'),
  address: Yup.string().required('School address is required'),
  type: Yup.string().required('School type is required'),
});

export const schoolsList = [
  'Public School',
  'Charter School',
  'Magnet School',
  'Private School',
  'Religious (Faith-Based) School',
  'Montessori School',
  'Vocational School',
  'Home School',
  'Other',
];

export default function AddSchoolModal() {
  const { closeModal, isOpen, modal } = useModal();

  const [addTemporarySchoolBody, addTemporarySchoolResponse] =
    useAddTemporarySchoolMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      type: '',
    },
    validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        const res = await addTemporarySchoolBody({
          name: values.name,
          address: values.address,
          type: values.type,
        }).unwrap();
        closeModal();
        modal?.props?.onSuccess?.(res);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Modal isOpen={isOpen('AddSchoolModal')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_45rem] [--top:_10%]">
        <Modal.CloseButton />

        <form
          className="flex flex-col gap-5 py-4"
          onSubmit={formik.handleSubmit}
        >
          <Logo className="mx-auto w-48 lg:w-56" />

          <AuthDetails className="mt-4">
            <AuthDetails.Title>
              Please fill the form to add school!
            </AuthDetails.Title>
          </AuthDetails>

          <Input
            placeholder="School Name"
            error={formik.touched.name && formik.errors.name}
            label={'Enter School Name'}
            {...formik.getFieldProps('name')}
          />

          <Textarea
            placeholder="School Address"
            error={formik.touched.address && formik.errors.address}
            classNames={{
              label:
                '!block !font-medium !text-[#484848] !text-base xl:!text-lg',
              input:
                'block w-full px-3 !py-3 text-base xl:text-lg border focus:border  border-[#DCDCDC] !rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center focus:border-[#C73E9D] focus:ring-1 focus:ring-[#C73E9D] transition-all duration-200 ease-in-out',
              error: '!border-[#F41F52] focus:border-[#F41F52]',
              root: '!space-y-2 col-span-2',
              
            }}
            label={'Enter School Address'}
            {...formik.getFieldProps('address')}
            rows={2}
          />

          <Select
            classNames={{
              label:
                '!block !font-medium !text-[#484848] !text-base xl:!text-lg',
              input:
                'block w-full px-3 xl:!h-14 !py-3 text-base xl:text-lg border focus:border  border-[#DCDCDC] !rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center focus:border-[#C73E9D] focus:ring-1 focus:ring-[#C73E9D] transition-all duration-200 ease-in-out',
              error: '!border-[#F41F52] focus:border-[#F41F52]',
              section: '!space-y-4',
              root: '!space-y-2',
              dropdown: '!z-[99999999]',
            }}
            size="lg"
            label={'School Type'}
            placeholder={'Select School Type'}
            error={formik.touched.type && formik.errors.type}
            value={formik.values?.type}
            data={schoolsList}
            onChange={(value) => {
              formik.setFieldValue('type', value);
            }}
            rightSection={<ChevronDown />}
            
          />

          <Button
            className="max-w-none w-full mt-4"
            variant="primary"
            type="submit"
            isLoading={addTemporarySchoolResponse.isLoading}
          >
            Continue
          </Button>
        </form>
      </Modal.Content>
    </Modal>
  );
}
