import React from 'react';

export default function LoadingOverlay({ isLoading }) {
  if (!isLoading) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-[900000] grid place-items-center">
      <span class="loader"></span>
    </div>
  );
}
