import { CheckIcon } from '@mantine/core';
import LoadingOverlay from 'app/components/LoadingOverlay';
import Button from 'components/Button';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetAvatarListQuery,
  useUpdateAvatarUnder13Mutation,
} from 'store/apis/auth';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { selectAccessToken } from 'store/Slice/authSlice';
import analytics from 'utils/analytics';
import cn from 'utils/cn';

function SelectAvatar() {
  const { openModal } = useModal();

  const { data, isLoading } = useGetAvatarListQuery();

  const { data: myProfileData } = useGetMyProfileQuery();
  const accessToken = useSelector(selectAccessToken);

  const { checkProfileAndNavigate } = useFirebaseLogin();

  const avatarList = React.useMemo(() => {
    if (!data) {
      return [];
    }

    return Object.keys(data.data).map((key) => ({
      uri: data?.data[key],
      id: Number(key),
    }));
  }, [data]);

  const [selectedAvatar, setSelectedAvatar] = React.useState(null);

  const [updateProfileImage, updateProfileImageResult] =
    useUpdateAvatarUnder13Mutation();

  const navigate = useNavigate();

  const updateProfileImageHandler = async () => {
    try {
      if (!selectedAvatar) {
        openModal('WarningModal', {
          title: 'Error',
          message: 'Please select an avatar',
        });

        return;
      }

      await updateProfileImage({
        avatarId: selectedAvatar.id.toString(),
      }).unwrap();

      analytics.logEvent('select_avatar', {
        avatarId: selectedAvatar.id,
        profileImage: selectedAvatar.uri,
      });

      const user = myProfileData?.data;

      if (
        user.type === 'private_tutor' ||
        user.type === 'teacher' ||
        user.type === 'parent'
      ) {
        openModal('ParentPaymentWarning');
        return;
      }

      if (user.type === 'school_leader' || user.type === 'district_leader') {
        openModal('LeadersPaymentWarning');
        return;
      }

      if (user.type !== 'student') {
        openModal('TeacherPaymentWarning');
        return;
      }

      openModal('StudentPaymentWarning');
      checkProfileAndNavigate(user, accessToken);
    } catch (err) {
      openModal({
        name: 'WarningModal',
        props: {
          title: 'Error',
          message: err.data?.message || 'An error occurred',
        },
      });
    }
  };

  console.log('selectedAvatar', selectedAvatar);

  return (
    <section className="bg-[#D9E4E2] grid place-items-center min-h-screen">
      <div className="bg-white w-full max-w-[40rem] mx-auto p-5 rounded-lg grid gap-10">
        <Logo />
        <div
          space={4}
          flex={1}
          p={4}
          borderColor={'#00000033'}
          borderWidth={1}
          rounded={20}
        >
          <p className="text-center mx-auto font-medium text-lg text-[#2C3E50] max-w-[35ch]">
            Express yourself! Pick a learning buddy that matches your vibe and
            as you learn and earn, you can unlock outfits and cool accessories!
          </p>

          <div className="grid grid-cols-4 mx-auto mt-5 justify-between  border border-[#D9D9D9] rounded-xl w-full max-w-[35rem] p-4">
            {avatarList.map((avatar) => (
              <button
                key={avatar.id}
                className="px-4 py-3 relative"
                onClick={() => {
                  if (selectedAvatar?.id === Number(avatar?.id)) {
                    setSelectedAvatar(null);
                  } else {
                    setSelectedAvatar(avatar);
                  }
                }}
              >
                <div className="rounded-full relative size-14 lg:size-16 xl:size-20 overflow-hidden">
                  <div className="rounded-full relative size-14 lg:size-16 xl:size-20 overflow-hidden border-2 border-[#845B1E]">
                    <img
                      key={avatar.id}
                      src={avatar.uri}
                      className="size-full object-cover"
                      alt={'avatar'}
                    />
                    <div
                      className={cn(
                        'absolute inset-0 rounded-full bg-[#ffffff20] hidden size-full',
                        {
                          block: selectedAvatar?.id?.toString() === avatar.id,
                        },
                      )}
                    />
                  </div>
                  <div
                    className={cn(
                      'absolute top-0 right-0 rounded-full bg-[#C73E9D] hidden size-full p-1 z-30 opacity-50 place-items-center',
                      {
                        grid:
                          selectedAvatar?.id?.toString() ===
                          avatar.id?.toString(),
                      },
                    )}
                  >
                    <CheckIcon
                      color={
                        selectedAvatar?.id === Number(avatar.id)
                          ? '#fff'
                          : 'transparent'
                      }
                      size={20}
                    />
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
        <Button
          onClick={updateProfileImageHandler}
          isDisabled={updateProfileImageResult.isLoading || !selectedAvatar}
          isLoading={updateProfileImageResult.isLoading}
          variant={'primary'}
          className="w-full mt-4 max-w-none"
        >
          Confirm
        </Button>

        <LoadingOverlay
          isLoading={updateProfileImageResult.isLoading || isLoading}
        />
      </div>
    </section>
  );
}

export default SelectAvatar;
