import React, { useState } from 'react';

import sharedStyles from '../../../sharedStyle.module.scss';

import Logo from '../../../components/logo.png';

import HomeLogin from '../../../components/StudentsSecond.png';
import Arrow from '../../../components/Arrow-Left.png';

import {
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import ParentsDialog from '../dialog';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'store/store';
import { setBirthDate } from 'store/Slice/registerSlice';
import { NotificationManager } from 'react-notifications';
import analytics from 'utils/analytics';

export default function BirthDetail({ setStep }) {
  const [openParents, setOpenParents] = useState(false);
  const handleClose = () => {
    setOpenParents(false);
  };
  const dispatch = useAppDispatch();
  const { date } = useAppSelector((state) => state.registerReducer);
  const handleDate = (name, value) => {
    if (value === "") {
      dispatch(setBirthDate({ key: name, value }));
      return;
    }
    if (name === 'day' && value.length > 2) return;
    if (name === 'month' && value.length > 2) return;
    if (name === 'year' && value.length > 4) return;
    if (isNaN(parseInt(value))) return;
    dispatch(setBirthDate({ key: name, value: value }));
  };

  function isUnder13(birthDate) {
    // Parse the birth date string using moment
    const birth = moment(birthDate, 'DD/MM/YYYY');

    // Get the current date using moment
    const today = moment();

    // Calculate the age in years
    const age = today.diff(birth, 'years');

    // Check if the age is under 13
    return age < 13;
  }

  function handleNext() {
    if (date.day === '') {
      NotificationManager.error('Please enter a valid day', 'Error');
      return;
    }

    if (date.month === '') {
      NotificationManager.error('Please enter a valid month', 'Error');
      return;
    }

    if (date.month > 12) {
      NotificationManager.error('Please enter a valid month', 'Error');
      return;
    }

    if (date.year === '') {
      NotificationManager.error('Please enter a valid year', 'Error');
      return;
    }

    if (date.day > 31) {
      NotificationManager.error('Please enter a valid day', 'Error');
      return;
    }

    const day = parseInt(date.day);
    const month = parseInt(date.month);
    const year = parseInt(date.year);

    const isLeapYear = (year) => {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };

    if (isLeapYear(year)) {
      if (month === 2 && day > 29) {
        NotificationManager.error('Please enter a valid day', 'Error');
        return;
      }
    } else {
      if (month === 2 && day > 28) {
        NotificationManager.error('Please enter a valid day', 'Error');
        return;
      }
    }

    analytics.logEvent('set_birth_date', {
      birth_date: `${date.day}/${date.month}/${date.year}`,
      is_under_13: isUnder13(`${date.day}/${date.month}/${date.year}`),
    });

    if (isUnder13(`${date.day}/${date.month}/${date.year}`)) {
      setOpenParents(true);
      setStep((prevStep) => ({
        ...prevStep,
        underAge: isUnder13(`${date.day}/${date.month}/${date.year}`),
      }));
    } else {
      setStep((prevStep) => ({
        ...prevStep,
        current: 'phoneValidation',
        underAge: isUnder13(`${date.day}/${date.month}/${date.year}`),
      }));
    }
  }

  return (
    <Box className={`${sharedStyles.container}`}>
      <Stack className={`${sharedStyles.studentsContainer}`}>
        <Box className={sharedStyles.arrow}>
          <img
            src={Arrow}
            alt="img-discription"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setStep((prevStep) => ({
                ...prevStep,
                current: 'home',
              }));
            }}
          />
        </Box>
        <Box className={sharedStyles.imageLeft}>
          <img src={HomeLogin} alt="img-discription" />
        </Box>
      </Stack>
      <Stack className={`${sharedStyles.loginContainer}`}>
        <Box className={sharedStyles.logoWrapper}>
          <Box className={sharedStyles.arrow}>
            <img
              src={Arrow}
              alt="img-discription"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setStep((prevStep) => ({
                  ...prevStep,
                  current: 'home',
                }));
              }}
            />
          </Box>
          <Box className={sharedStyles.logo}>
            <img src={Logo} alt={'logo'} />
          </Box>
        </Box>
        <Typography className="!font-medium !text-2xl">
          Already have an account?{' '}
          <span
            style={{ color: '#C73E9D', fontWeight: '700', cursor: 'pointer' }}
            onClick={() =>
              setStep((prevStep) => ({
                ...prevStep,
                current: 'login',
              }))
            }
          >
            Login
          </span>{' '}
        </Typography>
        <Stack className="w-10/12">
          <Typography
            className="!font-semibold !text-2xl sm:!text-2xl md:!text-2xl lg:!text-3xl"
            style={{ color: '#131515' }}
          >
            What is your birth date?
          </Typography>
          <Typography className="!font-normal !text-2xl">
            The G3MS learn to earn platform is designed to keep kids safe online while letting them learn anywhere on any device!
          </Typography> 
        </Stack>
        <Box
          sx={{
            width: '60%',
            display: 'flex',
            gap: '20px',
            '& .MuiOutlinedInput-input': {
              paddingTop: '25px',
              fontSize: '18px',
              fontWeight: 500,
            },
          }}
          className="flex-col sm:flex-row !gap-0 sm:!gap-3"
        >
          <FormControl variant="outlined">
            <InputLabel htmlFor="my-textfield" style={{ marginTop: '0px' }}>
              Month
            </InputLabel>
            <TextField
              id="month"
              name="month"
              variant="outlined"
              placeholder='Enter month'
              style={{ paddingTop: '16px' }}
              value={date.month}
              onChange={(event) =>
                handleDate(event.target.name, event.target.value)
              }
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="my-textfield" style={{ marginTop: '0px' }}>
              Day
            </InputLabel>
            <TextField
              id="day"
              name="day"
              variant="outlined"
              placeholder='Enter date'
              style={{ paddingTop: '16px' }}
              value={date.day}
              onChange={(event) =>
                handleDate(event.target.name, event.target.value)
              }
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="my-textfield" style={{ marginTop: '0px' }}>
              Year
            </InputLabel>
            <TextField
              id="year"
              name="year"
              variant="outlined"
              placeholder='Enter year'
              style={{ paddingTop: '16px' }}
              value={date.year}
              onChange={(event) =>
                handleDate(event.target.name, event.target.value)
              }
            />
          </FormControl>
        </Box>
        <Button
          variant={'contained'}
          size="large"
          sx={{
            width: '60%',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '20px',
            borderWidth: '2px',
          }}
          className={'primary_bg_btn'}
          onClick={handleNext}
        >
          Next
        </Button>
      </Stack>
      <ParentsDialog
        open={openParents}
        onClose={handleClose}
        setStep={setStep}
      />
    </Box>
  );
}
