import React, { useEffect } from 'react';

import sharedStyles from 'auth/sharedStyle.module.scss';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import LoadingOverlay from 'app/components/LoadingOverlay';
import useSocialSignIn from 'hooks/useSocialSignIn';
import AppleIcon from './assets/apple.png';
import ClassLinkIcon from './assets/classlink.png';
import GoogleIcon from './assets/google.png';

export default function SocialLogin({ setIsLoading, callback }) {
  const { googleSignIn, appleSignIn, classLinkSignIn, isLoading } =
    useSocialSignIn();

  useEffect(() => {
    if (setIsLoading !== undefined) {
      isLoading && setIsLoading(true);
      !isLoading && setIsLoading(false);
    }
  }, [isLoading, setIsLoading]);

  return (
    <div className="grid gap-4 mx-auto flex items-center justify-center text-center">
      <div className="grid grid-cols-[1fr,auto,1fr] gap-4 items-center">
        <hr className="border-[#DCDCDC]" />
        <p className="text-sm md:text-base xl:text-lg text-[#979C9E] font-medium">
          OR
        </p>
        <hr className="border-[#DCDCDC]" />
      </div>
      <div >
        <Box className={sharedStyles.buttowrap}>
          <Box className={sharedStyles.button}>
            <button disabled={isLoading}
              className={sharedStyles.loginButton}
              onClick={() => appleSignIn(callback)}
              style={{
                backgroundColor: '#FB1644',
                marginBottom: '10px',
              }}
            >
              <Typography className={sharedStyles.applink}>
                <img src={AppleIcon} alt={'apple logo icon'} />
              </Typography>
            </button>
            <Typography className={sharedStyles.appstatus}>
              Login with <span style={{ color: '#000' }}>Apple ID</span>
            </Typography>
          </Box>
          <Box className={sharedStyles.button}>
            <button disabled={isLoading}
              className={sharedStyles.loginButton}
              onClick={() => googleSignIn(callback)}
              style={{
                backgroundColor: '#DF4B3C',
                marginBottom: '10px',
              }}
            >
              <Typography className={sharedStyles.applink}>
                <img src={GoogleIcon} alt={'google logo icon'} />
              </Typography>
            </button>
            <p className={sharedStyles.appstatus}>
              Login with <span style={{ color: '#000' }}>Google</span>
            </p>
          </Box>
          <Box className={sharedStyles.button}>
            <button disabled={isLoading}
              className={sharedStyles.loginButton}
              style={{
                backgroundColor: '#1BA0ED',
                marginBottom: '10px',
              }}
              onClick={() => classLinkSignIn(callback)}
            >
              <Typography className={sharedStyles.applink}>
                <img src={ClassLinkIcon} alt={'class link logo icon'} />
              </Typography>
            </button>
            <Typography className={sharedStyles.appstatus}>
              Login with <span style={{ color: '#000' }}>Class Link</span>
            </Typography>
          </Box>
        </Box>
      </div>
      {/* <LoadingOverlay isLoading={isLoading} /> */}
    </div>
  );
}
