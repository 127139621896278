import { LoadingOverlay } from '@mantine/core';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { selectAccessToken } from 'store/Slice/authSlice';

export default function PublicLayout({ children }) {
  const accessToken = useSelector(selectAccessToken);
  const { data, isLoading } = useGetMyProfileQuery();
  const { checkProfileAndNavigate } = useFirebaseLogin();

  useEffect(() => {
    if (accessToken && data?.data) {
      if (data?.data?.type?.toLowerCase() === 'student') {
        checkProfileAndNavigate(data?.data, accessToken);
      }
    }
  }, [accessToken, data]);

  return (
    <>
      {children}
      <LoadingOverlay visible={isLoading} />
    </>
  );
}
