import React from 'react';
import modals from '../index';
import ModalContext from '../state/ModalContext';
import { setModal } from '../state/ModalReducer';

/**
 * @typedef {keyof typeof modals} ModalName
 */

/**
 * Custom hook to manage modal state.
 * @returns {{
 *   openModal: (name: ModalName, props?: Object) => void,
 *   closeModal: () => void,
 *   modal: { name: ModalName, props?: Object } | undefined,
 *   isOpen: (name: ModalName) => boolean
 * }}
 */

export default function useModal() {
  const { state, dispatch } = React.useContext(ModalContext);

  const openModal = React.useCallback(
    (name, props) => {
      dispatch(setModal({ name, props }));
    },
    [dispatch],
  );

  const closeModal = React.useCallback(() => {
    dispatch(setModal(undefined));
  }, [dispatch]);

  const isOpen = React.useCallback(
    (name) => {
      return state?.modal?.name === name;
    },
    [state?.modal?.name],
  );

  return {
    openModal,
    closeModal,
    modal: state?.modal,
    isOpen,
  };
}
