import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { setUserDetails } from 'store/Slice/authSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getAvatarImages, selectAvatarImage } from 'store/Thunk/registerThunk';
import analytics from 'utils/analytics';
import sharedStyles from '../../sharedStyle.module.scss';
import styles from './studentSelect.module.scss';

const StudentSelectAvatar = ({ uuid, setImage, setStep }) => {

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const { avatarImages: images } = useAppSelector((state) => state.registerReducer);

  const [imageIndex, setImageIndex] = useState(-1);

  async function confirm() {
    if (imageIndex === -1) {
      NotificationManager.error('Please select an avatar', 'Error');
      return;
    }
    setImage(images[imageIndex]);

    setLoading(true);
    dispatch(selectAvatarImage({ url: images[imageIndex] }))
      .unwrap()
      .then((data) => {
        setLoading(false);
        localStorage.setItem('userData', JSON.stringify(data.data.user));
        dispatch(setUserDetails(data.data.user))
        analytics.logEvent('upload_avatar', { user_id: data?.data?.user?.id });
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.error('Error while selecting avatar', 'Error');
        console.log(err);
        analytics.logEvent('upload_avatar_error', { user_id: uuid });
      });
  }

  useEffect(() => {
    setLoading(true);
    dispatch(getAvatarImages()).unwrap().then(() => {
      setLoading(false);
      analytics.logEvent('get_avatar_images');
    }).catch((err) => {
      console.log(err);
      analytics.logEvent('get_avatar_images_error');
    });
  }, [dispatch]);

  return (
    <div className={`${sharedStyles.whiteContainerBox} ${styles.selectAvatar}`}>
      <h1 className={styles.title}>Select your Avatar</h1>
      <div className={styles.grid}>
        {images.map((image, index) => (
          <button
            key={index}
            className={styles.avatar}
            onClick={() => setImageIndex(index)}
          >
            <img
              key={index}
              className={imageIndex === index ? styles.selected : ''}
              src={image}
              alt="avatar"
            />
          </button>
        ))}
      </div>

      <CircularProgress
        size={50}
        style={{ display: loading ? 'block' : 'none', margin: 'auto', marginTop: '20px' }}
      />

      <Box className="flex mt-5 w-full justify-center">
        <button
          onClick={confirm}
          className={sharedStyles.loginButton}
          style={{ backgroundColor: '#C73E9D', maxWidth: '111px' }}
        >
          Confirm
        </button>
      </Box>
    </div>
  );
};

export default StudentSelectAvatar;
