import React from 'react';

import { auth } from 'api/firebase';
import AuthPage from 'components/AuthPage';
import { useNavigate } from 'react-router-dom';
import analytics from 'utils/analytics';

import AuthDetails from 'components/AuthDetails';
import Button from 'components/Button';
import Input from 'components/Input';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import SocialLogin from 'components/SocialLogin';
import { useFormik } from 'formik';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import { useLoginWithUsernameMutation } from 'store/apis/auth';
import * as Yup from 'yup';
import getErrorMessage from 'utils/getErrorMessage';

export default function LoginUser() {
  const navigate = useNavigate();

  const { openModal } = useModal();

  const {
    isLoading: firebaseLoading,
    checkProfileAndNavigate,
    loginWithFirebase,
  } = useFirebaseLogin();

  const [loginWithUsername, loginWithUsernameRes] =
    useLoginWithUsernameMutation();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        analytics.logEvent('login_form_submission', {
          username: values.username,
        });

        const res = await loginWithUsername({
          username: values.username,
          password: values.password,
        }).unwrap();

        const customToken = res?.data?.customToken;
        const user = res?.data?.user;

        if (customToken) {
          await auth?.signInWithCustomToken(customToken);

          const accessToken = await auth?.currentUser?.getIdToken();

          // console.log('accessToken', accessToken)

          if (accessToken) {
            checkProfileAndNavigate(user, accessToken);
          } else {
            openModal('WarningModal', {
              title: 'Failed to get access token.',
            });
          }
        } else {
          openModal('WarningModal', {
            title: 'Failed to get access token.',
          });
        }
      } catch (err) {
        console.log(err?.data?.code);
        if (err?.data?.code === 'INVALID_USERNAME') {
          openModal('WarningModal', {
            title: 'Login Failed',
            description: getErrorMessage(err, 'Failed to get access token.'),
            onContinue: () => {
              navigate('/birth');
            },
          });
          return;
        }
        openModal('WarningModal', {
          title: 'Login Failed',
          description: getErrorMessage(err, 'Failed to get access token.'),
        });
      }
    },
  });

  const socialAuthCallback = async ({ success, ...rest }) => {
    console.log(rest);
    if (success) {
      await loginWithFirebase();
    }
  };

  return (
    <AuthPage className="gap-4">
      <Logo className="w-32 mb-4" />
      <AuthDetails>
        <AuthDetails.TwoText
          leftText="Don't have an account?"
          rightText="Sign Up"
          rightTextUrl="/birth"
          className="mb-2"
        />
        <AuthDetails.Title>Welcome Back!</AuthDetails.Title>
        <AuthDetails.Description>
          Give your students the support they need to close learning gaps.
        </AuthDetails.Description>
        <Button className="mx-auto" onClick={() => navigate('/qr')}>
          Scan QR Code
        </Button>
      </AuthDetails>

      <form className="grid gap-4 w-full" onSubmit={formik.handleSubmit}>
        <Input
          placeholder="test@example.com or happystudent123"
          error={formik.touched.username && formik.errors.username}
          label={'Email or Username'}
          {...formik.getFieldProps('username')}
        />
        <Input
          placeholder="********"
          type={'password'}
          label="Password"
          error={formik.touched.password && formik.errors.password}
          {...formik.getFieldProps('password')}
        />

        <Button
          variant={'primary'}
          type="submit"
          isLoading={loginWithUsernameRes.isLoading || firebaseLoading }
          className="max-w-[20rem] w-full mx-auto mt-2"
        >
          Login
        </Button>
      </form>
      <SocialLogin callback={socialAuthCallback} />
    </AuthPage>
  );
}
