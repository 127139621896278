import React from 'react';

import useModal from '../../hook/useModal';
import Modal from '../../components/Modal';
import success from './assets/success.svg';
import AuthDetails from 'components/AuthDetails';
import Button from 'components/Button';

export default function OtpSuccessModal() {
  const { closeModal, isOpen, modal } = useModal();

  return (
    <Modal
      isOpen={isOpen('OtpSuccessModal')}
      onClose={() => {
        closeModal();
        modal?.props?.onContinue();
      }}
    >
      <Modal.Content className="[--max-width:_30rem]">
        <Modal.CloseButton />
        <div className="flex flex-col gap-8 py-4">
          <img
            src={success}
            alt="success"
            className="size-8 md:size-10 lg:size-12 mx-auto"
            size="sm"
            mx={'auto'}
          />

          <AuthDetails.Title>🎉 Verification Successful! 🎉</AuthDetails.Title>

          <AuthDetails.Description>
            Your phone number has been verified. You're all set to start
            exploring the benefits of G3MS
          </AuthDetails.Description>

          <Button
            className="max-w-none w-full"
            variant="primary"
            onClick={modal?.props?.onContinue}
          >
            Continue
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}
