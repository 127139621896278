import { apiSlice } from '..';

import qs from 'query-string';
import './types';

export const profileApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Retrieves the current user's profile.
     * @returns {TProfileResponse}
     */
    getMyProfile: builder.query({
      query: () => 'user/profile/me',
      providesTags: ['MyProfile'],
    }),
    getMyProfileWithToken: builder.query({
      query: (token) => {
        return {
          url: 'user/profile/me',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ['MyProfile'],
    }),
    updateMyProfile: builder.mutation({
      query: (body) => ({
        url: 'user/profile/me',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['MyProfile'],
    }),
    completeProfile: builder.mutation({
      query: (body) => ({
        url: 'user/profile/complete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MyProfile'],
    }),
    deleteMyProfile: builder.mutation({
      query: () => ({
        url: 'user/profile/me',
        method: 'DELETE',
      }),
      invalidatesTags: ['MyProfile'],
    }),
    updateProfileImage: builder.mutation({
      query: (data) => ({
        url: 'user/profile/image',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['MyProfile'],
    }),
    getUserProfile: builder.query({
      query: (id) => `users/${id}/profile`,
      providesTags: ['SingleProfile'],
    }),
    searchProfile: builder.query({
      query: ({ page = 1, limit = 20, search = '' }) =>
        'users/discover/search?' + qs.stringify({ page, limit, search }),
      providesTags: ['Search'],
    }),

    getMoreSearchProfile: builder.query({
      query: ({ page = 1, limit = 20, search = '' }) =>
        'users/discover/search?' + qs.stringify({ page, limit, search }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          profileApi.util.updateQueryData(
            'searchProfile',
            {
              search: arg.search,
              page: 1,
              limit: 20,
            },
            (draft) => {
              console.log('found data');
              draft?.data?.users?.push(...data.data?.users);
              draft.data.meta = data.data.meta;
            },
          ),
        );
      },
    }),
    getUserQrCode: builder.query({
      query: () => '/user/get-student-qr',
    }),
    getUserQrById: builder.query({
      query: (id) => `students/get-qr/${id}`,
    }),
  }),
});

export const {
  useGetMyProfileQuery,
  useSearchProfileQuery,
  useGetUserProfileQuery,
  useUpdateProfileImageMutation,
  useDeleteMyProfileMutation,
  useUpdateMyProfileMutation,
  useLazyGetMyProfileQuery,
  useCompleteProfileMutation,
  useLazyGetMyProfileWithTokenQuery,
  useGetMoreSearchProfileQuery,
  useGetUserQrCodeQuery,
  useGetUserQrByIdQuery,
} = profileApi;
