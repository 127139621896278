import React from 'react';

import AuthDetails from 'components/AuthDetails';
import AuthPage from 'components/AuthPage';
import Button from 'components/Button';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import TelephoneInput from 'components/TelephoneInput';
import {
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input/input';
import { useNavigate } from 'react-router-dom';
import { useCheckPhoneNumberExistsMutation } from 'store/apis/auth';
import { useSendPhoneOtpMutation } from 'store/apis/otp';
import analytics from 'utils/analytics';
import getErrorMessage from 'utils/getErrorMessage';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  phone: Yup.string().required('Phone number is required'),
  dialCode: Yup.string().required('Country is required'),
});

export default function PhoneNumberValidation() {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);

  const birthDate = searchParams.get('birthDate');

  const { openModal } = useModal();

  const [sendPhoneOtp, sendPhoneOtpRes] = useSendPhoneOtpMutation();
  const [checkIsPhoneAvailable, checkIsPhoneAvailableRes] =
    useCheckPhoneNumberExistsMutation();

  const formik = useFormik({
    initialValues: {
      phone: '',
      dialCode: '+1',
    },
    onSubmit: async (values) => {
      try {
        if (!isValidPhoneNumber(values.phone)) {
          formik.setFieldError('phone', 'Invalid phone number');
          return;
        }

        const checkPhoneRes = await checkIsPhoneAvailable({
          phone: values.phone,
        }).unwrap();

        if (checkPhoneRes.data.isPhoneNumberAvailable === false) {
          openModal('WarningModal', {
            title: 'This phone number is already registered with us.',
            description: 'Please login to the associated account.',
            onContinue: () => {
              navigate('/login');
            },
          });
          return;
        }

        const res = await sendPhoneOtp({
          phoneNumber: parsePhoneNumber(values.phone).nationalNumber,
          countryCode: formik.values.dialCode,
        }).unwrap();

        analytics.logEvent('phone_number_validation', {
          phone: values.phone,
          dialCode: values.dialCode,
          screen: 'PhoneNumberValidation',
        });
        navigate(
          `/otp-validation?phone=${values.phone}&phoneToken=${res.data.verificationSid}&birthDate=${birthDate}`,
        );
      } catch (error) {
        openModal('WarningModal', {
          title: 'Something went wrong',
          description: getErrorMessage(error, 'Something went wrong.'),
        });
      }
    },
    validationSchema: validationSchema,
  });

  return (
    <AuthPage className="gap-4 content-center">
      <Logo className="w-32 mb-4" />
      <AuthDetails>
        <AuthDetails.TwoText
          leftText="Already have an account?"
          rightText="Login"
          rightTextUrl="/login"
        />
        <AuthDetails.Title>Verify Your Phone Number</AuthDetails.Title>
        <AuthDetails.Description>
          To ensure the security of your account, we wil send you a 6 digit code
          One Time Password. Please provide your phone number to verify your
          identity and access all the features of G3MS.
        </AuthDetails.Description>
      </AuthDetails>
      <form className="flex flex-col gap-5 py-4" onSubmit={formik.handleSubmit}>
        <TelephoneInput
          label={'Phone Number'}
          onChange={(value) => {
            formik.setFieldValue('phone', value);
          }}
          value={formik.values.phone}
          onCountryChange={({ callingCode, country }) => {
            formik.setFieldValue(
              'dialCode',
              callingCode?.toString()?.includes('+')
                ? callingCode
                : `+${callingCode}`,
            );

            formik.setFieldValue('phone', '');
          }}
        />

        <Button
          variant={'primary'}
          isLoading={
            sendPhoneOtpRes.isLoading || checkIsPhoneAvailableRes.isLoading
          }
          className={'max-w-[25rem] mt-4 mx-auto'}
          type={'submit'}
        >
          Next
        </Button>
      </form>
    </AuthPage>
  );
}
