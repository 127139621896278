// ** React Import
import { useCallback, useEffect, useRef, useState } from 'react';

// ** MUI Import
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

// ** Third Party Components
import themeConfig from 'configs/themeConfig';
import PerfectScrollbar from 'react-perfect-scrollbar';

// ** Component Imports
import Drawer from './Drawer';
import VerticalNavHeader from './VerticalNavHeader';

// ** Util Import
import { hexToRGBA } from '@core/utils/hex-to-rgba';
import { person1, person2 } from 'asset';

import CustomCircular from '@core/components/CustomCircular';
import WebSocketListener from '@core/components/Websocket';
import { Typography } from '@mui/material';
import { useQuery } from 'api/hooks';
import config from 'configs/config';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setJoinedSchoolAssignment,
  setSchoolAssignmentStatus,
  setSchoolAssignmentStep,
  setSelectedAssignment,
  setTimerDurationSecs,
} from 'store/Slice/schoolAssignmentSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { updateUserProfile } from 'store/Thunk/authThunk';
import { updateDoneQuiz } from 'store/Thunk/quizThunk';
import {
  getDueTodaySchoolAssignments,
  joinNonPinSchoolAssignment,
} from 'store/Thunk/schoolAssignmentThunk';
import { getTeacherData } from 'store/Thunk/teacherThunk';
import checkbox from './checkbox.svg';
import './style.css';
import cn from 'utils/cn';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { useGetAssignmentsQuery } from 'store/apis/assignments';

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const StyledBoxForShadow = styled(Box)(({ theme }) => ({
  top: 120,
  left: -8,
  zIndex: 2,
  display: 'none',
  position: 'absolute',
  pointerEvents: 'none',
  width: 'calc(100% + 15px)',
  height: theme.mixins.toolbar.minHeight,
  '&.d-block': {
    display: 'block',
  },
}));

const treeArr = [
  {
    icon: person1,
    name: 'Cavin Doe',
    type: "Math's Teacher",
  },
  {
    icon: person2,
    name: 'Juliana Doe',
    type: "Math's Teacher",
  },
];

const Navigation = (props) => {
  // ** Props
  const {
    hidden,
    settings,
    afterVerticalNavMenuContent,
    beforeVerticalNavMenuContent,
    verticalNavMenuContent: userVerticalNavMenuContent,
  } = props;

  const { data, isLoading } = useGetMyProfileQuery();
  // const isEmailVerified = data?.data?.isEmailVerified;
  const isEmailVerified = true;

  // ** States
  const [groupActive, setGroupActive] = useState([]);
  const [currentActiveGroup, setCurrentActiveGroup] = useState([]);
  const { userData } = useAppSelector((state) => state.userReducer);
  const { user } = useAppSelector((state) => state.authReducer);
  const { teacherList } = useAppSelector((state) => state.teacherReducer);
  const { showTop } = useAppSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      dispatch(getTeacherData({ studentId: user?.id }));
    }
  }, [user?.id]);

  // ** Ref
  const shadowRef = useRef(null);

  // ** Hooks
  const theme = useTheme();

  // ** Var
  const skin = '';
  // const { skin } = settings;
  const {
    afterVerticalNavMenuContentPosition,
    beforeVerticalNavMenuContentPosition,
  } = themeConfig;

  const getG3MSPercentage = () => {
    let percentage = 0;
    if (userData && Object.keys(userData).length) {
      const g3msScore = userData?.gemsTokens || 0;
      const totalG3MS = userData?.totalGems || 0;

      percentage = (g3msScore * 100) / (totalG3MS * 10);
    }
    return isNaN(percentage) ? 0 : percentage;
  };

  const getVideoWatchPercentage = () => {
    let percentage = 0;
    if (userData && Object.keys(userData).length) {
      const totalWatchVideos = userData?.totalWatchVideos || 0;
      const wachedVideos = userData?.watchedVideosCount || 0;

      percentage = (wachedVideos * 100) / totalWatchVideos;
    }
    return isNaN(percentage) ? 0 : percentage;
  };

  const getMasterSkillPercentage = () => {
    let percentage = 0;
    if (userData && Object.keys(userData).length) {
      // const totalQuiz = Number(userData?.totalQuiz) || 0;
      const masteredSkillArr = userData?.masteredSkill?.filter((obj) => obj);
      const masteredSkill = Number(masteredSkillArr?.length) || 0;

      const totalSkillsArr = userData?.totalSkills?.filter((obj) => obj);
      const totalSkills = Number(totalSkillsArr?.length) || 0;
      percentage = (masteredSkill * 100) / totalSkills;
    }
    return isNaN(percentage) ? 0 : percentage;
  };

  const getMasterSkillLength = () => {
    let length = 0;
    if (userData && Object.keys(userData).length) {
      // const totalQuiz = Number(userData?.totalQuiz) || 0;
      const masteredSkillArr = userData?.masteredSkill?.filter((obj) => obj);
      length = Number(masteredSkillArr?.length) || 0;
    }
    return length;
  };

  // ** Fixes Navigation InfiniteScroll
  const handleInfiniteScroll = (ref) => {
    if (ref) {
      // @ts-ignore
      ref._getBoundingClientRect = ref.getBoundingClientRect;
      ref.getBoundingClientRect = () => {
        // @ts-ignore
        const original = ref._getBoundingClientRect();

        return { ...original, height: Math.floor(original.height) };
      };
    }
  };

  // ** Scroll Menu
  const scrollMenu = (container) => {
    if (
      beforeVerticalNavMenuContentPosition === 'static' ||
      !beforeVerticalNavMenuContent
    ) {
      container = hidden ? container.target : container;
      if (shadowRef && container.scrollTop > 0) {
        // @ts-ignore
        if (!shadowRef.current.classList.contains('d-block')) {
          // @ts-ignore
          shadowRef.current.classList.add('d-block');
        }
      } else {
        // @ts-ignore
        shadowRef.current.classList.remove('d-block');
      }
    }
  };

  const shadowBgColor = () => {
    if (skin === 'semi-dark' && theme.palette.mode === 'light') {
      return `linear-gradient(${
        theme.palette.customColors.darkBg
      } 5%,${hexToRGBA(
        theme.palette.customColors.darkBg,
        0.85,
      )} 30%,${hexToRGBA(
        theme.palette.customColors.darkBg,
        0.5,
      )} 65%,${hexToRGBA(
        theme.palette.customColors.darkBg,
        0.3,
      )} 75%,transparent)`;
    } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
      return `linear-gradient(${
        theme.palette.customColors.lightBg
      } 5%,${hexToRGBA(
        theme.palette.customColors.lightBg,
        0.85,
      )} 30%,${hexToRGBA(
        theme.palette.customColors.lightBg,
        0.5,
      )} 65%,${hexToRGBA(
        theme.palette.customColors.lightBg,
        0.3,
      )} 75%,transparent)`;
    } else {
      return `linear-gradient(${
        theme.palette.background.default
      } 5%,${hexToRGBA(theme.palette.background.default, 0.85)} 30%,${hexToRGBA(
        theme.palette.background.default,
        0.5,
      )} 65%,${hexToRGBA(
        theme.palette.background.default,
        0.3,
      )} 75%,transparent)`;
    }
  };

  const ScrollWrapper = hidden ? Box : PerfectScrollbar;

  return (
    <Drawer {...props} className={cn(props.className, 'drawer')}>
      <VerticalNavHeader {...props} />
      {beforeVerticalNavMenuContent &&
      beforeVerticalNavMenuContentPosition === 'fixed'
        ? beforeVerticalNavMenuContent(props)
        : null}
      {(beforeVerticalNavMenuContentPosition === 'static' ||
        !beforeVerticalNavMenuContent) && (
        <StyledBoxForShadow
          ref={shadowRef}
          sx={{ background: shadowBgColor() }}
        />
      )}
      <Box
        sx={{
          position: 'relative',
          overflowY: 'auto',
          overflowX: 'hidden',
          height: 'calc(100vh - 64px)',
        }}
      >
        {beforeVerticalNavMenuContent &&
        beforeVerticalNavMenuContentPosition === 'static'
          ? beforeVerticalNavMenuContent(props)
          : null}
        {userVerticalNavMenuContent ? (
          userVerticalNavMenuContent(props)
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'auto',
                  backgroundColor: '#E9F6F3',
                  padding: '12px',
                  margin: '0px 10px',
                  borderRadius: '12px',
                }}
              >
                <div
                  style={{
                    fontWeight: '600',
                    fontSize: '22px',
                    lineHeight: '28px',
                    color: '#C73E9D',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    // backgroundColor: 'red',
                    width: '100%',
                    marginBottom: '12px',
                  }}
                >
                  <p>Classwork Due Today:</p>
                </div>

                {NavBarItems()}
              </div>

              <div className={'SideBarFooter px-4'}>
                <p className="text-base font-semibold text-[#C73E9D]">
                  The more your learn,{' '}
                  <span className="text-[#131515]">the more you earn!</span>
                </p>

                <div className="grid grid-cols-3 gap-4 mt-2">
                  <Box flex={1} textAlign={'center'}>
                    <CustomCircular
                      size="4rem"
                      progressColor="#C73E9D"
                      remainProgressColor="#FAEAF5"
                      percentage={getG3MSPercentage()}
                      value={userData?.gemsTokens || 0}
                    />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        display: 'block',
                        color: '#C73E9D',
                        fontSize: '10px',
                      }}
                      variant="caption"
                    >
                      $G3MS Total
                    </Typography>
                  </Box>{' '}
                  <Box flex={1} textAlign={'center'}>
                    <CustomCircular
                      size="4rem"
                      // rotate={30}
                      progressColor="#20A58A"
                      remainProgressColor="#E4F2EE"
                      percentage={getMasterSkillPercentage()}
                      value={getMasterSkillLength()}
                    />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        display: 'block',
                        fontSize: '10px',
                      }}
                      variant="caption"
                    >
                      <span style={{ color: '#C73E9D' }}>Skills</span>{' '}
                      <span className="text-[#131515]">Mastered</span>
                    </Typography>
                  </Box>{' '}
                  <Box flex={1} textAlign={'center'}>
                    <CustomCircular
                      size="4rem"
                      progressColor="#4CBFA7"
                      remainProgressColor="#E4F2EE"
                      // progressColor="#C73E9D"
                      // remainProgressColor="#FAEAF5"
                      percentage={getVideoWatchPercentage()}
                      value={userData?.watchedVideosCount || 0}
                    />
                    <Typography
                      sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        display: 'block',
                        fontSize: '10px',
                      }}
                      variant="caption"
                    >
                      <span style={{ color: '#C73E9D' }}>Lesson Videos</span>{' '}
                      <span className="text-[#131515]">Watched</span>
                    </Typography>
                  </Box>
                </div>
                {/* <Box
                  mt={10}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'center'}
                >
                  <button
                    className="bg-[#20A58A] w-full text-white px-4 py-4 rounded-2xl font-bold text-lg lg:text-xl flex items-center justify-center gap-2"
                    sx={{
                      fontWeight: 700,
                      fontSize: 20,
                      textTransform: 'none',
                    }}
                    onClick={() => {
                      navigate('/app/student/leaderboard/?topic=both');
                    }}
                  >
                    Top 10 Score Squad
                  </button>
                </Box> */}

                {/*  <div className="border border-[#00000033] rounded-xl px-2 mt-4 py-2">
                  <button className="flex items-center gap-3 justify-start py-3 px-2 hover:bg-gray-100 w-full rounded-md">
                    <img
                      className="size-6"
                      src="/svg/side-links/assignment.svg"
                      alt="assignment"
                    />
                    <span className="font-medium text-[#84818A] text-sm sm:text-base lg:text-lg">
                      My Assignments
                    </span>
                  </button>
                  <button className="flex items-center gap-3 justify-start py-3 px-2 hover:bg-gray-100 w-full rounded-md">
                    <img
                      className="size-6"
                      src="/svg/side-links/cast.svg"
                      alt="daily-lesson"
                    />
                    <span className="font-medium text-[#84818A] text-sm sm:text-base lg:text-lg">
                      Daily Lesson
                    </span>
                  </button>
                  <button className="flex items-center gap-3 justify-start py-3 px-2 hover:bg-gray-100 w-full rounded-md">
                    <img
                      className="size-6"
                      src="/svg/side-links/daily-challenges.svg"
                      alt="Daily Challenges"
                    />
                    <span className="font-medium text-[#84818A] text-sm sm:text-base lg:text-lg">
                      Daily Challenges
                    </span>
                  </button>
                  <button className="flex items-center gap-3 justify-start py-3 px-2 hover:bg-gray-100 w-full rounded-md">
                    <img
                      className="size-6"
                      src="/svg/side-links/daily-quiz.svg"
                      alt="Daily Quiz"
                    />
                    <span className="font-medium text-[#84818A] text-sm sm:text-base lg:text-lg">
                      Daily Quiz
                    </span>
                  </button>
                </div> */}
              </div>
            </div>

            <div className="px-4 pb-8 flex justify-center items-center mt-full">
              <p>
                <span style={{ color: '#C44298' }}>© G3MS.</span> All Right
                Reserved
              </p>
            </div>
          </div>
        )}
        {afterVerticalNavMenuContent &&
        afterVerticalNavMenuContentPosition === 'static'
          ? afterVerticalNavMenuContent(props)
          : null}
      </Box>
      {afterVerticalNavMenuContent &&
      afterVerticalNavMenuContentPosition === 'fixed'
        ? afterVerticalNavMenuContent(props)
        : null}
    </Drawer>
  );
};

export default Navigation;

const NavBarItems = () => {
 

  const dispatch = useAppDispatch();
  const query = useQuery();
  const assignment = query.get('assignment');

  const [selected, setSelected] = useState('');
  const { schoolAssignmentTodayList, step } = useAppSelector(
    (state) => state.schoolAssignmentReducer,
  );
  const { user } = useAppSelector((state) => state.authReducer);

  const { doneQuiz } = useAppSelector((state) => state.quizReducer);

  console.log(schoolAssignmentTodayList, 'schoolAssignmentTodayList');

  const [navItems, setNavItems] = useState([
    {
      id: 1,
      title: 'My Daily Practice',
      daily: true,
      isLive: false,
      done: false,
      isSelected: false,
    },
  ]);

   const { data } = useGetAssignmentsQuery(user?.id,{
    skip: !user?.id,
   });

   console.log('users data', data , user?.id);


  useEffect(() => {
    dispatch(
      updateDoneQuiz({
        userId: user?.id,
      }),
    );
  }, [dispatch, user?.id]);

  useEffect(() => {
    if (doneQuiz) {
      const dailyPracticeIdx = navItems.findIndex((item) => item.daily);
      const items = [...navItems];
      if (dailyPracticeIdx !== -1 && !items[dailyPracticeIdx].done) {
        items[dailyPracticeIdx].done = doneQuiz;
        setNavItems(items);
      }
    }
  }, [doneQuiz, navItems]);

  // useEffect(() => {
  //   dispatch(updateUserProfile());
  // }, [dispatch]);

  const [reloadList, setReloadList] = useState(0);
  useEffect(() => {
    if (user?.id) {
      dispatch(getDueTodaySchoolAssignments(user?.id));
    }
  }, [dispatch, user?.id, reloadList]);

  useEffect(() => {
    if (schoolAssignmentTodayList.length) {
      const items = [
        ...schoolAssignmentTodayList.map((item) => {
          return {
            id: item.id,
            title: `${item.classroom.topic.name}: ${item.type}`,
            isLive:
              item.pinCode &&
              item.school_assignment_session.length > 0 &&
              !(item.school_assignment_user[0].status === 'Completed'),
            done: item.school_assignment_user[0].status === 'Completed',
            isSelected: false,
          };
        }),
      ];
      setNavItems((prev) => [...items, prev[prev.length - 1]]);
    }
  }, [schoolAssignmentTodayList]);

  useEffect(() => {
    if (assignment) {
      const idx = navItems.findIndex((item) => item.id === assignment);
      if (idx !== -1) {
        setSelected(idx);
      }
    }
  }, [assignment, navItems]);

  const navigate = useNavigate();
  const TodoTasks = () => {
    return navItems.map((item, index) => {
      const onSelectHandler = (item, index) => {
        if (selected === index) {
          return;
        }
        if (item.done) {
          setSelected(index);
          if (!item.daily) {
            const selectedAssignment = schoolAssignmentTodayList.find(
              (assignment) => assignment.id === item.id,
            );
            dispatch(setSelectedAssignment(selectedAssignment));
            navigate(`/app/student/?assignment=${item.id}`);
          } else {
            navigate(`/app/student`);
            dispatch(setSelectedAssignment({}));
          }
          return;
        } else {
          setSelected(index);
          item.daily
            ? navigate(`/app/student`)
            : navigate(`/app/student/?assignment=${item.id}`);
          if (!item.daily) {
            const selectedAssignment = schoolAssignmentTodayList.find(
              (assignment) => assignment.id === item.id,
            );
            dispatch(setSelectedAssignment(selectedAssignment));
            if (selectedAssignment.pinCode) {
              dispatch(setSchoolAssignmentStep(0));
              dispatch(setSchoolAssignmentStatus('Start'));
              dispatch(setJoinedSchoolAssignment({}));
            } else {
              dispatch(
                joinNonPinSchoolAssignment({
                  id: selectedAssignment.id,
                  userId: user?.id,
                }),
              )
                .unwrap()
                .then((response) => {
                  const timeDuration = selectedAssignment.timeDuration * 60;
                  dispatch(setTimerDurationSecs(timeDuration));
                  dispatch(setSchoolAssignmentStep(4));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            dispatch(setSelectedAssignment({}));
          }
        }
      };
      return (
        <div
          key={item.id}
          onClick={() => onSelectHandler(item, index)}
          className="todo-item"
          style={{
            display: 'flex',
            backgroundColor:
              index === selected && item.daily
                ? '#fff'
                : index === selected
                ? '#BAE3DB'
                : 'transparent',
            alignItems: 'center',
            border:
              index === selected && item.daily ? '2px solid #20A58A' : 'unset',
            padding: ' 16px 6px',
            borderRadius: '12px',
            width: '100%',
            height: '56px',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '12px',
              opacity: item.done ? '40%' : '100%',
              alignItems: 'center',
            }}
          >
            {item.done ? (
              <div className="icon">
                <img src={checkbox} alt="" />
              </div>
            ) : (
              <div
                className="icon"
                style={{
                  width: '24px',
                  height: '24px',
                  border: '2px solid #979C9E',
                  borderRadius: '4px',
                }}
              ></div>
            )}

            <div
              className=""
              style={{
                fontWeight: '600',
                fontSize: '15px',
                lineHeight: '22px',
                color: '#131515',
              }}
            >
              {item.title}
            </div>
          </div>
          {item.isLive ? (
            <div
              className="icon"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FCEAE9',
                width: '64px',
                height: '30px',
                gap: '6px',
                borderRadius: '16px',
                color: '#DF3023',
              }}
            >
              <div
                style={{
                  borderRadius: '50%',
                  width: '8px',
                  height: '8px',
                  backgroundColor: '#DF3023',
                }}
              ></div>
              <div> Live</div>
            </div>
          ) : item.done ? (
            <div
              className="icon"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#C73E9D',
                width: '58px',
                height: '30px',
                gap: '6px',
                borderRadius: '16px',
                color: '#FFFFFF',
              }}
            >
              <div
                style={{
                  color: '#FFFFFF',
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '22px',
                }}
              >
                {' '}
                Done
              </div>
            </div>
          ) : null}
        </div>
      );
    });
  };

  const handleWebSocketMessage = useCallback(
    (message) => {
      const { action, data } = message;
      if (action && data !== undefined) {
        const { assignment_id } = data;
        const { state } = data;
        if (assignment_id) {
          const index = navItems.findIndex((item) => item.id === assignment_id);
          if (index !== -1 && navItems[index].done === false) {
            switch (action) {
              case 'initiate':
                setNavItems((prev) => {
                  const items = [...prev];
                  items[index].isLive = true;
                  return items;
                });
                break;

              case 'start':
                if (step === 1 || step === 2) {
                  dispatch(setSchoolAssignmentStep(4));
                }
                if (state) {
                  dispatch(setJoinedSchoolAssignment(state));
                }
                break;

              case 'pause':
                dispatch(setSchoolAssignmentStep(1));
                dispatch(setSchoolAssignmentStatus('Resume'));
                break;

              case 'cancel':
                setNavItems((prev) => {
                  const items = [...prev];
                  items[index].isLive = false;
                  return items;
                });
                dispatch(setSchoolAssignmentStep(0));
                dispatch(setSchoolAssignmentStatus('Start'));
                dispatch(setJoinedSchoolAssignment({}));
                break;

              case 'timeout':
                setNavItems((prev) => {
                  const items = [...prev];
                  items[index].isLive = false;
                  return items;
                });
                break;

              default:
                break;
            }
          }
        }
        if (action === 'created') {
          setReloadList((prev) => prev + 1);
        }
      }
    },
    [step, navItems, dispatch],
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '10px',
      }}
    >
      {TodoTasks()}

      <WebSocketListener url={config.WS} onMessage={handleWebSocketMessage} />
    </div>
  );
};
