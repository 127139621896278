import LoadingOverlay from 'app/components/LoadingOverlay';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApproveOrRejectChildMutation } from 'store/apis/payment';
import cn from 'utils/cn';
import styles from './styles.module.scss';
import Modal from 'components/Modal/components/Modal';

const ChildApprovalSettings = () => {
  const navigate = useNavigate();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(false);

  const isButtonEnabled = isCheckboxChecked && isSwitchEnabled;

  const [searchParams] = useSearchParams();

  const confirmationId = searchParams.get('confirmationId');
  const parentId = searchParams.get('parentId');

  const [approveOrReject, approveOrRejectResponse] =
    useApproveOrRejectChildMutation();

  const approveOrRejectChild = async (confirmationStatus) => {
    try {
      const res = await approveOrReject({ confirmationId, confirmationStatus });
      if (confirmationStatus === 'CONFIRMED') {
        const phoneToken = res.data?.data?.verificationSid;
        const phone = res.data?.data?.parentConfirmation?.parent?.phone;
        const localParentId = res.data?.data?.parentConfirmation?.parent?.id;
        localStorage.setItem('childApproval', 'approved');
        if (res.data.isParentPhoneVerified) {
          navigate({
            pathname: '/plans',
            search: `?confirmationId=${confirmationId}&parentId=${
              parentId || localParentId
            }`,
          });
        } else {
          navigate({
            pathname: '/otp-validation',
            search: `?confirmationId=${confirmationId}&parentId=${
              parentId || localParentId
            }&phoneToken=${phoneToken}&redirectTo=plans&phone=${phone}`,
          });
        }
      }

      if (confirmationStatus === 'REJECTED') {
        localStorage.clear();
        navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => {}}
      closeOnBackdropClick={false}
      closeOnEscape={false}
    >
      <Modal.Content className="[--top:_clamp(2rem,5vh,10rem)] ">
        <header className={styles.convinceToUpgradeHead}>
          <h3 className={styles.heading}>Child Account Approval Needed</h3>
        </header>
        <main className={styles.convinceToUpgradeBody}>
          <h3 className={styles.heading}>
            Before creating your account, please approve to access G3MS.
          </h3>
          <h3 className={styles.heading} style={{ textAlign: 'left' }}>
            Free Features Summary
          </h3>
          <p className={styles.bodyTxt} style={{ textAlign: 'left' }}>
            Here’s what Amir Khan can do with their free G3MS account:
          </p>
          <ul>
            <li>✅ Access state-aligned Math lessons.</li>
            <li>✅ Earn tokens to customize their avatar.</li>
            <li>✅ Use the camera for interactive challenges.</li>
            <li>
              ✅ Include a visual progress bar showing what features are
              unlocked for free.
            </li>
          </ul>
          <div>
            <div className="flex items-center gap-5">
              <input
                className="w-10 h-8 mx-2"
                type="checkbox"
                checked={isCheckboxChecked}
                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              />
              <h3 className={styles.bodyTxt}>
                I agree to allow my child to use the camera for challenges and
                interactive lessons. I have read and accept the{' '}
                <a className="hover:underline" href="#">
                  Terms & Conditions
                </a>
                .
              </h3>
            </div>
            <div className="flex items-center gap-5">
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  id="switch-2"
                  type="checkbox"
                  className="peer sr-only"
                  checked={isSwitchEnabled}
                  onChange={(e) => setIsSwitchEnabled(e.target.checked)}
                />
                <div className="peer h-4 w-11 rounded-full border bg-slate-200 after:absolute after:-top-1 after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#35A28B] peer-checked:after:translate-x-full peer-focus:ring-[#35A28B]"></div>
              </label>
              <div>
                <h2 className={styles.bodySmTxt} style={{ fontWeight: '600' }}>
                  Allow your kid videos to be shared
                </h2>
                <h3 className={styles.bodyTxt}>
                  I allow my child’s videos and images to be shared within the
                  G3MS community and external platforms through shared links.
                </h3>
              </div>
            </div>
          </div>
        </main>
        <footer className={styles.convinceToUpgradeFooter}>
          <button
            onClick={() => {
              approveOrRejectChild('REJECTED');
            }}
          >
            Reject
          </button>
          <button
            onClick={() => approveOrRejectChild('CONFIRMED')}
            // onClick={() => navigate(`/auth/login?type=parent`)}
            disabled={!isButtonEnabled}
            className={
              isButtonEnabled ? styles.activeButton : styles.disabledButton
            }
          >
            Approve & Continue
          </button>
        </footer>
        <LoadingOverlay isLoading={approveOrRejectResponse.isLoading} />
      </Modal.Content>
    </Modal>
  );
};

export default ChildApprovalSettings;
