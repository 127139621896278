

export default function getUserName(data) {
  const fallBackText = data?.fallBack ? data?.fallBack : 'N/A';

  const username = data?.username !== null ? data?.username : fallBackText;

  const name = data?.firstName !== null ? data?.firstName : '';
  const lastname = data?.lastName !== null ? data?.lastName : '';

  const fullname = name || lastname ? `${name} ${lastname}` : fallBackText;

  return data.type === 'student' ? username : fullname;
}

export const getGrade = (text) => {
  if (text === null || text === undefined) {
    return 'N/A';
  }
  return text + ' Grade';
};
