import { useDispatch } from 'react-redux';

import { auth } from 'api/firebase';
import useModal from 'components/Modal/hook/useModal';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { useLazyGetMyProfileQuery } from 'store/apis/profile';
import { setUserDetails } from 'store/Slice/authSlice';
import analytics from 'utils/analytics';
import useHandleUserNavigation from './useHandleUserNavigation';

export default function useFirebaseLogin() {
  const [getMyProfile, getMyProfileResponse] = useLazyGetMyProfileQuery();
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const navigation = useNavigate();

  const { loginUser } = useHandleUserNavigation();

  const checkProfileAndNavigate = async (user, token, nextNavigation) => {
    if (user.completedProfile && user.type !== 'student') {
      if (
        user.type === 'private_tutor' ||
        user.type === 'teacher' ||
        user.type === 'parent'
      ) {
        openModal('ParentPaymentWarning');
        return;
      }

      openModal('TeacherPaymentWarning');
      return;
    }

    if (user.completedProfile) {
      analytics.logEvent('login', {
        userId: user.id,
        email: user.email,
      });

      dispatch(
        setUserDetails({
          user,
          accessToken: token,
        }),
      );

      loginUser(user, token);

      NotificationManager.success(
        'Login Success',
        'You have successfully logged in.',
        2000,
      );

      return;
    }

    if (nextNavigation) {
      nextNavigation?.();
      return;
    }

    if (user.type.toLowerCase() === 'student' && !user.email) {
      // go to email verification

      return;
    }

    openModal('AccountCreatedModal', {
      onContinue: () => {
        navigation('/complete-profile',{
          replace: true,
        });
      },
    });
  };

  const loginWithFirebase = async () => {
    try {
      const accToken = await auth?.currentUser?.getIdToken();
      if (!accToken) {
        NotificationManager.error(
          'Login Failed',
          'Failed to get access token.',
          2000,
        );
        return;
      }

      const res = await getMyProfile().unwrap();

      const user = res?.data;

      if (!user) {
        openModal('WarningModal', {
          title: 'Login Failed',
          description:
            'There was no user found with this email. Please sign up.',
        });

        NotificationManager.error(
          'Login Failed',
          'Failed to get user details.',
          2000,
        );
        return;
      }

      await checkProfileAndNavigate(user, accToken);

      analytics.logEvent('login', {
        userId: user.id,
        email: user.email,
      });
    } catch (err) {
      console.log(err, 'err');

      analytics.logEvent('login_error', {
        error: err,
      });

      const message = Array.isArray(err?.data?.message)
        ? err?.data?.message[0]
        : err?.data?.message;

      openModal('WarningModal', {
        title: 'Login Failed',
        description: message || 'Failed to get access token.',
      });

      NotificationManager.error(
        'Login Failed',
        message || 'Failed to get access token.',
        2000,
      );
    }
  };

  return {
    loginWithFirebase,
    isLoading: getMyProfileResponse.isLoading,
    checkProfileAndNavigate,
  };
}
