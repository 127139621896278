import axios from 'axios';
import config from 'configs/config';
import store from './store';
import { auth } from 'api/firebase';

const { CancelToken } = axios;

// Create a cancellation token manager
class CancelTokenManager {
  constructor() {
    this.sourceMap = new Map();
  }

  addToken(key) {
    if (this.sourceMap.has(key)) {
      // If a request with the same key is already in progress, cancel it
      this.sourceMap.get(key).cancel('Duplicate request canceled');
    }

    const source = CancelToken.source();
    this.sourceMap.set(key, source);
    return source.token;
  }

  removeToken(key) {
    this.sourceMap.delete(key);
  }
}

const cancelTokenManager = new CancelTokenManager();

const API = axios.create({
  baseURL: config.BASE_URL,
});

API.interceptors.request.use((req) => {
  const accessToken =
    localStorage.getItem('accessToken') ||
    store.getState().authReducer.accessToken;
  const userData =
    localStorage.getItem('userData') || store.getState().authReducer.userData;
  if (accessToken && userData) {
    const uid = JSON.parse(userData).id;

    if (uid) {
      req.headers.Authorization = `Bearer ${accessToken}`;
      req.headers.uid = uid;
    }
  }

  // Generate a unique key for the request
  const requestKey = JSON.stringify(req);

  // Add a cancel token for the request
  req.cancelToken = cancelTokenManager.addToken(requestKey);

  return req;
});

API.interceptors.response.use(
  (response) => {
    // Remove the cancel token for the completed request
    const requestKey = JSON.stringify(response.config);
    cancelTokenManager.removeToken(requestKey);
    return response;
  },
  async (error) => {
    if (error?.response?.data?.code === 'JWT_TOKEN_EXPIRED') {
      const userData = localStorage.getItem('userData');
      if (userData) {
        // const user = JSON.parse(userData);
        // API.post('/api/v1/users/login', {
        //   email: user.email,
        //   firebaseId: user.firebaseId,
        //   userType: user.type.toLowerCase(),
        // })
        //   .then(async (res) => {
        //     const token = auth.currentUser?.getIdToken();
        //     console.log(
        //       '================> Refresh user token <===============',
        //       res,
        //     );
        //     localStorage.setItem('accessToken', token);

        //     // Retry the failed request
        //     const requestKey = JSON.stringify(error.config);
        //     const newRequest = {
        //       ...error.config,
        //       cancelToken: cancelTokenManager.addToken(requestKey),
        //     };
        //     return API(newRequest);
        //   })
        //   .catch((err) => {
        //     console.log(
        //       '================> Could not refresh token <===============',
        //       err,
        //     );
        //   });

        const accessToken = await auth.currentUser?.getIdToken();
        const token = store.getState().authReducer.accessToken;

        localStorage.setItem('accessToken', token || accessToken);

        const requestKey = JSON.stringify(error.config);
        const newRequest = {
          ...error.config,
          cancelToken: cancelTokenManager.addToken(requestKey),
        };
        return API(newRequest);
      }
    }
    // Remove the cancel token for the failed request
    const requestKey = JSON.stringify(error.config);
    cancelTokenManager.removeToken(requestKey);
    return Promise.reject(error);
  },
);

export default API;
