import React from 'react';

import Button from 'components/Button';
import Lottie from 'lottie-react';
import animation from '../../../../asset/lottie/bot-animation.json';
import Modal from '../../components/Modal';
import useModal from '../../hook/useModal';
import { useGetMyProfileQuery } from 'store/apis/profile';
import getUserName from 'utils/getUserName';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from 'app/components/LoadingOverlay';

export default function StudentPaymentWarning() {
  const { closeModal, isOpen, openModal } = useModal();
  const { data, isLoading } = useGetMyProfileQuery();

  const navigate = useNavigate();

  const userName = React.useMemo(() => {
    if (data?.data) {
      return getUserName({
        firstName: data?.data?.name,
        lastName: data?.data?.lastname,
        username: data?.data?.username,
        type: data?.data?.type ?? 'student',
      });
    }
    return '';
  }, [data]);

  return (
    <Modal isOpen={isOpen('StudentPaymentWarning')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_45rem] [--top:_5%]">
        {/* <Modal.CloseButton /> */}
        <div className="flex flex-col gap-8 py-4">
          <Lottie animationData={animation} className="mx-auto w-48 lg:w-56" />

          <div className="grid gap-4">
            <h1 className="text-[#2C3E50] font-bold text-lg xl:text-xl text-center mx-auto">
              🎉 Great Job, {userName}! You’ve Earned $50 G3MS Tokens! 🎉
            </h1>

            <p className="text-sm lg:text-base 2xl:text-xl text-black text-center mx-auto max-w-[45ch]">
              Use your tokens to unlock fun gift cards, cool avatar upgrades,
              and more exciting rewards!
            </p>
          </div>

          <h1 className="text-[#2C3E50] font-bold text-base xl:text-lg  text-center mx-auto">
            💎 “Ready to Glow Up?” 💎
          </h1>

          <ul className="text-sm xl:text-base lg:text-lg  2xl:text-xl text-black">
            <li>🎁 Redeem tokens for gift cards.</li>
            <li>✏️ AI Tutor for math, writing, and coding. </li>
            <li>📚 Unlock full lessons in ELA, Science, and more.</li>
            <li>🚀 Join coding challenges.</li>
            <li>🎓 Prep for high school & SAT/ACT.</li>
          </ul>

          <h1 className="text-[#2C3E50] font-bold text-base xl:text-lg  text-center mx-auto">
            🌟 Ready to Level Up? 🌟
          </h1>
          <h1 className="text-[#2C3E50] font-bold text-base xl:text-lg  text-center mx-auto">
            Try Premium free for 7 days! Then keep unlocking gift cards and
            <br />
            features for just $7.99/month!
          </h1>

          <div className="grid gap-4 grid-cols-1 xl:grid-cols-2">
            <Button
              className="max-w-none w-full"
              onClick={() => {
                openModal('RequestParentApproval');
              }}
            >
              Ask Your Parent to Upgrade!
            </Button>
            <Button
              className="max-w-none w-full"
              variant="primary"
              onClick={() => {
                closeModal();
                navigate('/plans', {
                  state: { isStudent: true },
                });
              }}
            >
              Unlock Premium Now!
            </Button>
          </div>
        </div>
      </Modal.Content>

      <LoadingOverlay isLoading={isLoading} />
    </Modal>
  );
}
