import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input/input';
import * as Yup from 'yup';
import Logo from '../../../../asset/logo.svg';

const validationSchema = Yup.object({
  // parentEmail: Yup.string().email('Invalid email').required('Required'),
  childFirstName: Yup.string().required('Your First name is required'),
  childLastName: Yup.string().required('Your Last name is required'),
  parentFirstName: Yup.string().required('Parents First name is required'),
  parentLastName: Yup.string().required('Parents Last name is required'),
  phone: Yup.string().required('Phone number is required'),
  dialCode: Yup.string().required('Country is required'),
});

export default function ParentsDialog({ open, onClose, setStep }) {
  const [selected, setSelected] = useState('US');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isInvalidError, setIsInvalidError] = useState(false);

  const formik = useFormik({
    initialValues: {
      parentEmail: '',
      phone: '',
      dialCode: '+1',
      country: 'US',
      childFirstName: '',
      childLastName: '',
      parentFirstName: '',
      parentLastName: '',
    },
    validationSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        if (!isValidPhoneNumber(values.phone)) {
          setFieldError('phone', 'Invalid phone number');
          return;
        }

        const parsedValue = parsePhoneNumber(values.phone);

        setStep({
          phone: parsedValue.number,
          dialCode: parsedValue.countryCallingCode,
          parentFirstName: values.parentFirstName,
          parentLastName: values.parentLastName,
          childFirstName: values.childFirstName,
          childLastName: values.childLastName,
        });
      } catch (error) {
        console.log(error);
      }
    },
  });



  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <img className="h-16 mt-8 mx-auto mb-5" src={Logo} alt="" />
      <DialogTitle
        className="!font-semibold !text-3xl sm:!text-3xl md:!text-2xl lg:!text-3xl"
        sx={{ color: '#000' }}
        fontWeight={600}
        textAlign={'center'}
      >
        Ask a Grownup?
      </DialogTitle>

      <span
        onClick={onClose}
        style={{
          position: 'absolute',
          right: '16px',
          top: '16px',
          width: '16px',
          height: '16px',
          cursor: 'pointer',
          background: '#f3f3f3',
          borderRadius: '100px',
          padding: '6px',
          boxSizing: 'content-box',
          border: '1px solid #eaeaea',
          boxShadow:
            '0px 11px 15px -7px rgba(76, 78, 100, 0.2),0px 24px 38px 3px rgba(76, 78, 100, 0.14),0px 9px 46px 8px rgba(76, 78, 100, 0.12)',
        }}
      >
        <img
          src="https://img.icons8.com/?size=100&id=79023&format=png&color=000000"
          alt="close"
        />
      </span>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
          <Box
            textAlign={'center'}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-input': {
                fontSize: '18px',
                fontWeight: 500,
              },
            }}
          >
            <div className="grid gap-6">
              <Typography
                className="!text-xl sm:!text-md md:!text-lg"
                sx={{
                  mx: 6,
                  color: '#000',
                  lineHeight: 1,
                }}
              >
                You can still join us, but before you start using the camera or
                claiming your rewards, we'll need a grownup's okay.
              </Typography>

              <section className="grid items-baseline grid-cols-1 md:grid-cols-2 md:gap-4">
                <div className="grid gap-2">
                  <label
                    className="block font-medium text-lg text-left color-[#484848]"
                    htmlFor="childFirstName"
                  >
                    Your First Name
                  </label>
                  <FormControl
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        fontSize: '14px !important',
                        borderRadius: '6px !important',
                      },
                    }}
                  >
                    <TextField
                      placeholder="Type your first name"
                      id="childFirstName"
                      variant="outlined"
                      style={{
                        fontSize: '14px !important',
                        fontWeight: 500,
                        borderRadius: '6px !important',
                      }}
                      error={
                        formik.touched.childFirstName &&
                        Boolean(formik.errors.childFirstName)
                      }
                      helperText={
                        formik.touched.childFirstName &&
                        formik.errors.childFirstName && (
                          <span className="text-red-500 text-sm w-full text-left">
                            {formik.errors.childFirstName}
                          </span>
                        )
                      }
                      {...formik.getFieldProps('childFirstName')}
                    />
                  </FormControl>
                </div>

                <div className="grid gap-2">
                  <label
                    className="block font-medium text-lg text-left color-[#484848]"
                    htmlFor="childLastName"
                  >
                    Your Last Name
                  </label>
                  <FormControl
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        fontSize: '14px !important',
                        borderRadius: '6px !important',
                      },
                    }}
                  >
                    <TextField
                      placeholder="Type your last name"
                      id="childLastName"
                      variant="outlined"
                      style={{
                        fontSize: '14px !important',
                        fontWeight: 500,
                        borderRadius: '6px !important',
                      }}
                      error={
                        formik.touched.childLastName &&
                        Boolean(formik.errors.childLastName)
                      }
                      helperText={
                        formik.touched.childLastName &&
                        formik.errors.childLastName && (
                          <span className="text-red-500 text-sm w-full text-left">
                            {formik.errors.childLastName}
                          </span>
                        )
                      }
                      {...formik.getFieldProps('childLastName')}
                    />
                  </FormControl>
                </div>
              </section>

              <section className="grid items-baseline grid-cols-1 md:grid-cols-2 md:gap-4">
                <div className="grid gap-2">
                  <label
                    className="block font-medium text-lg text-left color-[#484848]"
                    htmlFor="parentFirstName"
                  >
                    Parent's First Name
                  </label>
                  <FormControl
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        fontSize: '14px !important',
                        borderRadius: '6px !important',
                      },
                    }}
                  >
                    <TextField
                      placeholder="Type your parent's first name"
                      id="parentFirstName"
                      variant="outlined"
                      style={{
                        fontSize: '14px !important',
                        fontWeight: 500,
                        borderRadius: '6px !important',
                      }}
                      error={
                        formik.touched.parentFirstName &&
                        Boolean(formik.errors.parentFirstName)
                      }
                      helperText={
                        formik.touched.parentFirstName &&
                        formik.errors.parentFirstName && (
                          <span className="text-red-500 text-sm w-full text-left">
                            {formik.errors.parentFirstName}
                          </span>
                        )
                      }
                      {...formik.getFieldProps('parentFirstName')}
                    />
                  </FormControl>
                </div>

                <div className="grid gap-2">
                  <label
                    className="block font-medium text-lg text-left color-[#484848]"
                    htmlFor="parentLastName"
                  >
                    Parent's Last Name
                  </label>
                  <FormControl
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        fontSize: '14px !important',
                        borderRadius: '6px !important',
                      },
                    }}
                  >
                    <TextField
                      placeholder="Type your parent's last name"
                      id="parentLastName"
                      variant="outlined"
                      style={{
                        fontSize: '14px !important',
                        fontWeight: 500,
                        borderRadius: '6px !important',
                      }}
                      error={
                        formik.touched.parentLastName &&
                        Boolean(formik.errors.parentLastName)
                      }
                      helperText={
                        formik.touched.parentLastName &&
                        formik.errors.parentLastName && (
                          <span className="text-red-500 text-sm w-full text-left">
                            {formik.errors.parentLastName}
                          </span>
                        )
                      }
                      {...formik.getFieldProps('parentLastName')}
                    />
                  </FormControl>
                </div>
              </section>

              <div className="grid gap-2 w-full">
                <label className="w-full block font-medium text-lg text-left color-[#484848]">
                  Parent's Phone Number
                </label>
                <div className="flex flex-row gap-3 w-full">
                  <ReactFlagsSelect
                    selected={formik.values.country}
                    onSelect={(code) => {
                      formik.setFieldValue('country', code);
                    }}
                    searchable
                    showSelectedLabel={false}
                    searchPlaceholder="(000) 000 000"
                    className={`[&>button]:h-16 lg:[&>button]:h-14 react-flag--select`}
                  />
                  <div
                    className={`${
                      isInvalidError && 'phone-invalid--wrapper'
                    } react-phone--input w-full`}
                  >
                    <PhoneInput
                      country={formik.values.country}
                      value={formik.values.phone}
                      onChange={(value) => {
                        const parsedValue = parsePhoneNumber(value);
                        if (parsedValue) {
                          formik.setFieldValue('phone', parsedValue.number);
                          formik.setFieldValue(
                            'dialCode',
                            parsedValue.countryCallingCode,
                          );
                        }
                      }}
                      placeholder="(000) 000 000"
                      className="w-full h-16 lg:h-14 p-4 border border-gray-300 rounded-lg focus:outline-none"
                    />
                  </div>
                </div>

                {formik.touched.phone && formik.errors.phone && (
                  <p className="text-red-500 text-sm w-full text-left">
                    {formik.errors.phone}
                  </p>
                )}
              </div>
              <Box
                mt={4}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Button
                  size="large"
                  sx={{
                    mr: 2,
                    height: '46px',
                    width: '90%',
                    minWidth: '120px',
                  }}
                  variant="contained"
                  className={'primary_bg_btn'}
                  type="submit"
                >
                  Continue
                </Button>
              </Box>
            </div>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
