import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Slider from './Slider'
import Logo from '../../asset/logo.svg'
import AppStore from '../../asset/splash/app-store.svg'
import GooglePlay from '../../asset/splash/google-play.svg'

const Index = ({ setStep }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.modalOuterShadow}>
      <div className={styles.convinceToUpgradeContainer}>
        <header className={styles.convinceToUpgradeHead}>
          <img style={{maxHeight: '80px'}} className='mb-6' src={Logo} alt="" />
        </header>
        <Slider />
        {/* <main className={styles.convinceToUpgradeBody}>
            {[{name: 'Learn to Earn', img: CardMockup},{name: 'AI tutor', img: AITutor},{name: 'Videos', img: AIChat},{name: 'Quizzes', img: Quiz}]?.map((item, _index) => (
          <div className={styles.convinceToUpgradeBodyInner}>
            <img src={item?.img} alt="" />
            <h3 className={styles.heading}>{item?.name}</h3>
          </div>
            ))}
        </main> */}
        <div className={styles.convinceToUpgradeFooter}>
          <div className='flex justify-center mt-8'>
            <button className='mb-8' onClick={() => {
              setStep({
                current: 'home',
                underAge: false,
              })
            }}>Next</button>
          </div>

          <div className='flex flex-col lg:flex-row items-center justify-between gap-4 lg:gap-6'>
            <div className='flex flex-col lg:flex-row items-center gap-6 lg:gap-8'>
              <p className='text-xl font-bold text-[#2C3E50]'>Learn what matters, earn what counts with G3MS</p>
                <div className='flex items-center gap-3 lg:gap-4'>
                    <img className='h-10 object-cover cursor-pointer' src={AppStore} alt="" />
                    <img className='h-10 object-cover cursor-pointer' src={GooglePlay} alt="" />
                </div>
            </div>
            <div className='flex items-center gap-3 lg:gap-4'>
              <span className='text-sm text-[#2C3E50] underline cursor-pointer'>Terms of Service</span>
              <span className='text-sm text-[#2C3E50] underline cursor-pointer'>v14 (28)</span>
              <span className='text-sm text-[#2C3E50] underline cursor-pointer'>Privacy Policy</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;  