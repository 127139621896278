export default function getErrorMessage(
  err,
  defaultMessage = 'An error occurred',
) {
  const message = Array.isArray(err?.data?.message)
    ? err?.data?.message[0]
    : err?.data?.message;

  return message || defaultMessage;
}
