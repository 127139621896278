import { apiSlice } from '..';

export const schoolApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSchools: builder.query({
      query: body => {
        return {
          url: 'schools',
          method: 'GET',
          params: body,
        };
      },
    }),
    addTemporarySchool: builder.mutation({
      query: body => {
        return {
          url: 'user/add-school',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {useGetSchoolsQuery, useAddTemporarySchoolMutation} = schoolApi;
