// ** MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu';

// ** Components
import Navbar from './Navbar';
// import Autocomplete from 'layouts/components/Autocomplete'
import UserDropdown from '@core/layouts/components/shared-components/UserDropdown';
import SelectAssignmentList from 'app/student/components/main/elements/assignments/selectAssignmentList';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'iconsax-react';
import { useAppSelector } from 'store/store';

const AppBarContent = (props) => {
  const { user } = useAppSelector((state) => state.authReducer);
  const location = useLocation();
  const data = location?.state;
  const [videoTopic, setVideoTopic] = useState(data ? data : '');
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;

  return (
    <>
      <Navbar menuToggle={toggleNavVisibility} />
    </>
  );
};

export default AppBarContent;
