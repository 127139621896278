import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './Slice/authSlice';
import quizReducer from './Slice/quizSlice';
import registerReducer from './Slice/registerSlice';
import schoolAssignmentReducer from './Slice/schoolAssignmentSlice';
import teacherReducer from './Slice/teacherSlice';
import topicReducer from './Slice/topicSlice';
import userReducer from './Slice/userSlice';
import videoReducer from './Slice/videoSlice';

import { useDispatch, useSelector } from 'react-redux';
import { apiSlice } from './apis';
import uiReducer from './Slice/uiSlice';
import dressBotReducer from './Slice/dressBotSlice';
import sneakerBotReducer from './Slice/sneakerBot';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const combinedReducer = combineReducers({
  quizReducer,
  topicReducer,
  videoReducer,
  authReducer: persistedAuthReducer,
  registerReducer,
  userReducer,
  teacherReducer,
  schoolAssignmentReducer,
  uiReducer: uiReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  dressBot: dressBotReducer,
  sneakerBot: sneakerBotReducer,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

const persistor = persistStore(store);

export { persistor };
export default store;
