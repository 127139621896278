import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
export default function AddSchoolDialog({
  open,
  onClose,
  onCloseDialog,
  formData,
  setFormData,
  handleChangeAutocomplete,
}) {
  const SchoolTypesList = [
    { name: 'Regular' },
    { name: 'Special' },
    { name: 'Education' },
    { name: 'Career' },
    { name: 'Technical' },
    { name: 'Alternative Education' },
  ];
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [openTypes, setOpenTypes] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleYes = () => {
    const errors = {};
    if (!formData?.schoolName) {
      errors.schoolName = 'School Name is required';
    }
    if (!formData?.schoolAddress) {
      errors.schoolAddress = 'School Address is required';
    }
    if (!formData?.schoolType) {
      errors.schoolType = 'School Type is required';
    }
    setFormErrors(errors);
    if (!Object.keys(errors).length) {
      onClose();
    }
  };

  return (
    <Dialog fullWidth={true} open={open} onClose={onCloseDialog}>
      <DialogTitle
        sx={{ fontSize: '1.7vw !important', color: '#000' }}
        fontWeight={600}
        textAlign={'center'}
        mb={6}
      >
        Please fill the form to add school!
      </DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Stack fullWidth flex={1} alignItems={'flex-start'}>
            <Typography
              fullWidth
              className="!font-medium"
              style={{ color: '#131515' }}
              mb={2}
            >
              School Name
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter School Name"
              id="my-textfield"
              variant="outlined"
              size="medium"
              onChange={(e) =>
                setFormData({ ...formData, schoolName: e.target.value })
              }
              error={formErrors.schoolName}
              helperText={formErrors.schoolName}
            />
          </Stack>
          <Stack fullWidth flex={1} alignItems={'flex-start'}>
            <Typography
              fullWidth
              className="!font-medium"
              style={{ color: '#131515' }}
              mb={2}
            >
              School Address
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter School Address"
              id="my-textfield"
              variant="outlined"
              size="medium"
              onChange={(e) =>
                setFormData({ ...formData, schoolAddress: e.target.value })
              }
              error={formErrors.schoolAddress}
              helperText={formErrors.schoolAddress}
            />
          </Stack>
          <Stack fullWidth flex={1} alignItems={'flex-start'}>
            <Typography
              fullWidth
              className="!font-medium"
              style={{ color: '#131515' }}
              mb={2}
            >
              School Type
            </Typography>
            <Autocomplete
              id="schoolType"
              sx={{ width: '100%' }}
              open={openTypes}
              size="small"
              onOpen={() => setOpenTypes(true)}
              onClose={() => setOpenTypes(false)}
              isOptionEqualToValue={(option, value) => option.id === value}
              getOptionLabel={(option) => option.name || ''}
              options={SchoolTypesList}
              filterSelectedOptions
              value={formData?.schoolType}
              onChange={(event, value) => {
                handleChangeAutocomplete('schoolType')(event, value);
              }}
              renderOption={(props, option, state) => (
                <li {...props}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.selected} // Checkbox is checked for the selected option
                        onChange={() => {
                          // Handle the checkbox change event
                          handleChangeAutocomplete('schoolType')(
                            null,
                            state.selected ? null : option,
                          );
                        }}
                      />
                    }
                    label={option.name}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select School Type"
                  error={formErrors?.schoolType}
                  helperText={formErrors?.schoolType}
                  size="medium"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingTypes ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Stack>
        </Stack>
        <Box
          mt={8}
          display={'flex'}
          alignItems={'center'}
          gap={8}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Button
            variant={'outlined'}
            size="large"
            className={'primary_bg_btn'}
            onClick={onCloseDialog}
            sx={{
              flex: 1,
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
          >
            Cancel
          </Button>
          <Button
            size="large"
            className={'primary_bg_btn'}
            sx={{
              flex: 1,
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              borderWidth: '2px',
            }}
            variant="contained"
            onClick={handleYes}
          >
            Yes
          </Button>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
