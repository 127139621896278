import { apiSlice } from '..';

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verifyEmailOtp: builder.mutation({
      query: (body) => {
        return {
          url: '/auth/verify-email',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['MyProfile'],
    }),
    resendEmailOtp: builder.mutation({
      query: () => {
        return {
          url: '/auth/resend-email',
          method: 'POST',
        };
      },
    }),
    sendEmailOtp: builder.mutation({
      query: (body) => {
        return {
          url: '/auth/send-email-otp',
          method: 'POST',
          body: body,
        };
      },
    }),
    sendPhoneOtp: builder.mutation({
      query: (body) => {
        return {
          url: '/auth/send-otp',
          method: 'POST',
          body: body,
        };
      },
    }),
    verifyPhoneOtp: builder.mutation({
      query: (body) => {
        return {
          url: '/auth/verify-otp',
          method: 'POST',
          body: body,
        };
      },
    }),
  }),
});

export const {
  useVerifyEmailOtpMutation,
  useResendEmailOtpMutation,
  useSendPhoneOtpMutation,
  useVerifyPhoneOtpMutation,
  useSendEmailOtpMutation
} = authApi;
