import {
  appleProvider,
  auth,
  classLinkProvider,
  googleProvider,
} from 'api/firebase';
import Cookies from 'js-cookie';
import { useState } from 'react';

const useSocialSignIn = () => {
  const [isLoading, setIsLoading] = useState(false);

  async function googleSignIn(cb) {
    try {
      const res = await signInWithProvider(googleProvider);
      if (cb) {
        cb({ success: true, user: res });
      } else {
        return res;
      }
    } catch (error) {
      if (cb) {
        cb({ success: false, error });
      } else {
        return error;
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function appleSignIn(cb) {
    try {
      const res = await signInWithProvider(appleProvider);
      if (cb) {
        cb({ success: true, user: res });
      } else {
        return res;
      }
    } catch (error) {
      if (cb) {
        cb({ success: false, error });
      } else {
        return error;
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function classLinkSignIn(cb) {
    try {
      const res = await signInWithProvider(classLinkProvider);
      if (cb) {
        cb({ success: true, user: res });
      } else {
        return res;
      }
    } catch (error) {
      if (cb) {
        cb({ success: false, error });
      } else {
        return error;
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function signInWithProvider(provider) {
    setIsLoading(true);
    await auth.signOut();

    const allCookies = Cookies.get();

    for (const cookie in allCookies) {
      Cookies.remove(cookie);
    }

    const user = await auth.signInWithPopup(provider);
    setIsLoading(false);
    return user;
  }

  return {
    googleSignIn,
    appleSignIn,
    classLinkSignIn,
    isLoading,
  };
};

export default useSocialSignIn;
