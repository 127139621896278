import AppStore from 'asset/splash/app-store.svg';
import GooglePlay from 'asset/splash/google-play.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'utils/cn';

export default function OnboardingFooter() {
  const navigate = useNavigate();
  return (
    <div className={cn('grid gap-10 mt-4 px-4')}>
      <div className="flex justify-center">
        <button
          className="bg-[#C73E9D] px-4 py-4 w-full max-w-[20rem] text-white rounded-xl font-medium"
          onClick={() => {
            navigate('/home', { replace: true });
          }}
        >
          Next
        </button>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-between gap-4 lg:gap-6">
        <div className="flex flex-col lg:flex-row items-center gap-6 lg:gap-8">
          <p className="text-xl font-bold text-[#2C3E50] text-center">
            Learn what matters, earn what counts with G3MS
          </p>
          <div className="flex items-center gap-3 lg:gap-4">
            <img
              className="h-10 object-cover cursor-pointer"
              src={AppStore}
              alt=""
            />
            <img
              className="h-10 object-cover cursor-pointer"
              src={GooglePlay}
              alt=""
            />
          </div>
        </div>
        <div className="flex items-center gap-3 lg:gap-4">
          <span className="text-sm text-[#2C3E50] underline cursor-pointer">
            Terms of Service
          </span>
          <span className="text-sm text-[#2C3E50] underline cursor-pointer">
            v14 (28)
          </span>
          <span className="text-sm text-[#2C3E50] underline cursor-pointer">
            Privacy Policy
          </span>
        </div>
      </div>
    </div>
  );
}
