import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectAccessToken } from 'store/Slice/authSlice';

export default function PrivateLayout({ children }) {
  const accessToken = useSelector(selectAccessToken);

  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}
