import React from 'react';
import cn from 'utils/cn';
import TwoTextLink from './TwoTextLink';
import AuthSectionTitle from './AuthSectionTitle';
import AuthSectionDescription from './AuthSectionDescription';

export default function AuthDetails({ children, className = '' }) {
  return <div className={cn('grid gap-3 mb-4 content-center', className)}>{children}</div>;
}


AuthDetails.TwoText = TwoTextLink;
AuthDetails.Title = AuthSectionTitle;
AuthDetails.Description = AuthSectionDescription;