import React from 'react';
import cn from 'utils/cn';

export default function AuthSectionDescription({ children, className = '' }) {
  return (
    <p
      className={cn(
        'text-base xl:text-lg text-center text-black max-w-[50ch] mx-auto',
        className,
      )}
    >
      {children}
    </p>
  );
}
