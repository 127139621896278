import React from 'react';

import Button from 'components/Button';
import Lottie from 'lottie-react';
import animation from '../../../../asset/lottie/bot-animation.json';
import Modal from '../../components/Modal';
import useModal from '../../hook/useModal';

export default function AccountCreatedModal() {
  const { closeModal, isOpen, modal } = useModal();

  return (
    <Modal isOpen={isOpen('AccountCreatedModal')} onClose={closeModal}>
      <Modal.Content className="[--max-width:_45rem] [--top:_10%]">
        <Modal.CloseButton />
        <div className="flex flex-col gap-8 py-4">
          <Lottie animationData={animation} className="mx-auto w-48 lg:w-56" />

          <h1 className="text-[#2C3E50] font-bold text-lg md:text-xl xl:text-2xl text-center mx-auto">
            🎉 Congratulations! You’ve Earned $10 Tokens! 🎉
          </h1>

          <p className="text-sm md:text-base lg:text-lg  2xl:text-xl text-black text-center mx-auto max-w-[60ch]">
            Welcome to the G3MS community! You earned $10 Tokens to your wallet
          </p>

          <h1 className="text-[#2C3E50] font-bold text-base md:text-lg xl:text-xl text-center mx-auto">
            Want to earn $50 more?
          </h1>

          <p className="text-sm md:text-base lg:text-lg  2xl:text-xl text-black text-center mx-auto max-w-[60ch]">
            Complete your profile now and get closer to redeeming exciting gift
            cards from your favorite brands like Amazon, Visa, GameStop, and
            Sephora! 🎁 💳 🎁 💳
          </p>

          <Button
            className="max-w-none w-full"
            variant="primary"
            onClick={() => {
              closeModal();
              modal?.props?.onContinue?.();
            }}
          >
            Complete My Profile
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}
