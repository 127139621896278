import { apiSlice } from '..';

export const paymentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query({
      query: () => {
        return {
          url: 'payment-subscription/plans',
          method: 'GET',
        };
      },
    }),
    approveOrRejectChild: builder.mutation({
      query: ({ confirmationId, confirmationStatus }) => {
        return {
          url: '/auth/update-parent-child-confirmation-status',
          method: 'POST',
          body: {
            confirmationId,
            confirmationStatus,
          },
        };
      },
    }),
    iniTiatePayment: builder.mutation({
      query: ({ planId, userId }) => {
        return {
          url: '/payment-subscription/create-checkout-session',
          method: 'POST',
          body: {
            lineItems: [
              {
                paymentPlanId: planId,
                quantity: 1,
              },
            ],
            userId: userId,
          },
        };
      },
    }),
    getSessionDetails: builder.query({
      query: (sessionId) => {
        return {
          url: `payment-subscription/get-checkout-session/${sessionId}`,
          method: 'GET',
         
        };
      },
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useApproveOrRejectChildMutation,
  useIniTiatePaymentMutation,
  useGetSessionDetailsQuery,
} = paymentApi;
