import PlayIcon from 'icons/PlayIcon';
import React from 'react';

export default function FractionItem({ onClick, image, title, btnTitle }) {
  return (
    <div className="flex justify-between md:justify-around  lg:justify-between px-4 py-3 md:py-4 bg-fractionBg rounded-3xl">
      <div className='flex items-center gap-2 mb-2'>
        <img
          src={image}
          alt=""
          className="h-[4rem] md:h-[4rem] lg:h-[4rem] object-contain"
        />
       
          </div>
 
      <div>

          <h1 className="font-medium text-[#252843] text-sm xl:text-sm 2xl:text-sm text-left">
            {title}
          </h1>
          <button
        className="bg-primary-100 text-white rounded-2xl px-4 py-3 mt-3 lg:px-5 lg:py-4 font-medium !text-xs xl:!text-sm 2xl:!text-base flex items-center gap-4 justify-center w-full"
        onClick={onClick}
      >
        <PlayIcon className="text-white size-5 lg:size-6 " />
        <span>{btnTitle}</span>
      </button>
      </div>
   
    </div>
  );
}
