import { CircularProgress } from '@mui/material';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyGetMyProfileWithTokenQuery } from 'store/apis/profile';
import analytics from 'utils/analytics';

export default function TokenLogin() {
  const [searchParams] = useSearchParams();

  const { checkProfileAndNavigate } = useFirebaseLogin();

  const navigate = useNavigate();



  const [getProfile, { error, data, isLoading }] =
    useLazyGetMyProfileWithTokenQuery();

  const token = searchParams.get('token');

  const redirectTo = searchParams.get('redirectTo');

  console.log('token', token, redirectTo);

  useEffect(() => {
    const loginWithToken = async () => {
      try {
        const res = await getProfile(token).unwrap();

        const user = res?.data;
        await checkProfileAndNavigate(user, token);

        analytics.logEvent('login', {
          user_id: data?.data?.id,
        });
      } catch (error) {
        navigate('/');
      }
    };

    if (token) {
      loginWithToken();
    } else {
      navigate('/');
    }
  }, [token]);

  return (
    <div className="absolute inset-0 grid place-items-center">
      <div className="grid place-items-center gap-4">
        <CircularProgress color="secondary" />
        <h1 className="text-black text-center mt-4 font-bold text-xl ">
          Loading
        </h1>
      </div>
    </div>
  );
}
