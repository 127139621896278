import AuthDetails from 'components/AuthDetails';
import AuthPage from 'components/AuthPage';
import Button from 'components/Button';
import Logo from 'components/Logo';
import ScanWithQrButton from 'components/ScanWithQrButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function HomeScreen() {
  const navigate = useNavigate();
  return (
    <AuthPage>
      <Logo className="w-36 mb-10" />
      <AuthDetails>
        <AuthDetails.TwoText
          leftText="Already have an account?"
          rightText="Login"
          rightTextUrl="/login"
        />
        <AuthDetails.Title>Do you have a QR code?</AuthDetails.Title>
      </AuthDetails>
      <ScanWithQrButton />
      <Button className="mt-4 mx-auto" onClick={() => navigate('/birth')}>
        Sign Up
      </Button>
    </AuthPage>
  );
}
