import { Avatar, LoadingOverlay } from '@mantine/core';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import { auth } from 'api/firebase';
import { AppStore, PlayStore } from 'asset';
import AuthDetails from 'components/AuthDetails';
import AuthPage from 'components/AuthPage';
import Button from 'components/Button';
import Logo from 'components/Logo';
import Cookies from 'js-cookie';
import React from 'react';
import { IoShareSocialOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiSlice } from 'store/apis';
import {
  useGetMyProfileQuery,
  useGetUserQrByIdQuery,
} from 'store/apis/profile';
import { logoutUser } from 'store/Slice/authSlice';
import { Resolution, usePDF } from 'react-to-pdf';
import { Margin } from 'mdi-material-ui';
import domtoimage from 'dom-to-image-more';

const options = {
  // default is `save`
  method: 'open',
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.HIGH,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: 'letter',
    // default is 'portrait'
    orientation: 'landscape',
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    mimeType: 'image/png',
    qualityRatio: 1,
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break,
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true,
    },
    // see https://html2canvas.hertzen.com/configuration for more options
  },
};

export default function WebRedirect() {
  const { data, isLoading } = useGetMyProfileQuery();
  const cardRef = React.useRef();

  const { toPDF, targetRef } = usePDF({
    filename: `QR-${data?.data?.id}.pdf`,
    options,
  });

  // const { data: qrCodeData, isLoading: qrCodeDataLoading } =
  //   useGetUserQrCodeQuery();

  const { data: qrCodeData, isLoading: qrCodeDataLoading } =
    useGetUserQrByIdQuery(data?.data?.id, {
      skip: !data?.data?.id,
    });

  const onShare = async () => {
    try {
      // convert qr code to image and download
      const image = document.createElement('img');
      image.src = qrCodeData?.data?.qr;
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0);
        const dataUrl = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = `QR-${data?.data?.id}.png`;
        a.click();
      };
    } catch (error) {
      console.error(error);
    }
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logoutUser());
    localStorage.clear();
    const cookies = Cookies.get();
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }
    auth.signOut();
    navigate('/');
  };

  const handleDownload = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="">
      <div className="flex justify-between items-center p-4 px-6 bg-white shadow-md sticky top-0 w-full z-10">
        <Logo className="w-20 lg:w-24 xl:w-32 m-0" />

        <IconButton
          onClick={handleLogout}
          sx={{ fontSize: '30px' }}
          aria-label="delete"
        >
          <LogoutIcon sx={{ fontSize: '24px' }} />
        </IconButton>
      </div>
      <AuthPage className="gap-4 content-center p-4">
        <Logo className="w-32 mb-4" />
        <AuthDetails>
          <AuthDetails.TwoText
            leftText="Already have an account?"
            rightText="Login"
            rightTextUrl="/login"
          />
          <AuthDetails.Title>Download Our Mobile App</AuthDetails.Title>
          <AuthDetails.Description>
            Looks like you are on mobile device and for the best experience
            click below to download our native iOS or android app.
          </AuthDetails.Description>
        </AuthDetails>

        <div className="grid gap-10">
          <div className="grid grid-cols-2 gap-4">
            <div
              onClick={() =>
                handleDownload(
                  'https://apps.apple.com/us/app/g3ms/id1625313492',
                )
              }
              className="flex flex-col items-center justify-center flex-1 gap-6"
            >
              <img
                src={AppStore}
                alt="Download on the App Store"
                className="size-24"
              />

              <p className="text-center max-w-[16ch] mx-auto text-lg xl:text-xl 2xl:text-2xl">
                Download on the App Store
              </p>
            </div>
            <div
              onClick={() =>
                handleDownload(
                  'https://play.google.com/store/apps/details?id=com.g3ms',
                )
              }
              className="flex flex-col items-center justify-center flex-1 gap-6"
            >
              <img
                src={PlayStore}
                alt="Get it on Google Play"
                className="size-24"
              />

              <p className="text-center max-w-[16ch] mx-auto text-lg xl:text-xl 2xl:text-2xl">
                Download on the App Store
              </p>
            </div>
          </div>

          <div
            className="border border-gray-[#00000033] rounded-2xl p-4 grid gap-4 w-full"
            ref={targetRef}
          >
            <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl font-semibold">
              Student Detail
            </p>
            <div className="w-full">
              <div className="flex gap-4 items-center justify-between">
                <div className="flex gap-4 items-center">
                  <div className="flex flex-col justify-center items-center gap-2">
                    {data?.data?.profileImage ? (
                      <img
                        src={data?.data?.profileImage}
                        alt="Profile"
                        className="size-8 sm:16 rounded-full lg:size-20 xl:size-28 2xl:size-48"
                      />
                    ) : (
                      <div className="size-12 sm:16 rounded-full lg:size-20 xl:size-28 2xl:size-32 grid place-items-center bg-[#F0F0F0] text-[#929292]">
                        <p className="text-sm font-medium xl:text-2xl">
                          {data?.data?.name?.charAt(0).toUpperCase()}{' '}
                          {data?.data?.lastname?.charAt(0).toUpperCase()}
                        </p>
                      </div>
                    )}
                    <div>
                      <p className="text-xs sm:text-sm md:text-base lg:text-xl 2xl:text-2xl text-[#20A58A] max-sm:max-w-[10ch] text-center">
                        {data?.data?.name} {data?.data?.lastname}
                      </p>
                      <p className="text-xs sm:text-sm md:text-base lg:text-xl 2xl:text-2xl text-[#20A58A] max-sm:max-w-[10ch] text-center">
                        {'('}
                        {data?.data?.id?.slice(0, 4)}...{' )'}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-6 gap-y-4 content-start">
                    <div className="">
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl text-[#929292]">
                        Username
                      </p>
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl">
                        {data?.data?.username}
                      </p>
                    </div>
                    <div className="">
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl text-[#929292]">
                        Grade
                      </p>
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl">
                        {data?.data?.grade?.name}
                      </p>
                    </div>
                    <div className="col-span-2">
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl text-[#929292]">
                        School Name
                      </p>
                      <p className="text-base sm:text-lg xl:text-xl 2xl:text-2xl">
                        {data?.data?.school?.name}
                      </p>
                    </div>
                  </div>
                </div>
                <img
                  src={qrCodeData?.data?.qr}
                  alt="QR Code"
                  className="w-[4.5rem] h-[4.5rem] sm:w-32 sm:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 2xl:w-56 2xl:h-56"
                />
              </div>
            </div>
          </div>

          <Button
            variant={'primary'}
            className="w-full text-base max-w-full h-16 flex items-center justify-center gap-2"
            onClick={onShare}
          >
            <IoShareSocialOutline
              className="mr-2 text-white group-hover:text-[#C44297]"
              size={24}
            />
            <span>Download & Share</span>
          </Button>
        </div>
      </AuthPage>
      <LoadingOverlay visible={isLoading || qrCodeDataLoading} />
    </div>
  );
}
